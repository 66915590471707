import React, { useState } from 'react';
import Loading from '../../loading/loading.component';
import SingleCommentComponent from './single-comment-component';
import CommentInputComponent from './comment-input.component';
import _ from 'lodash';
import { useComment } from '../../../hooks/post/useComment';

interface CommentsComponentProps {
  post: any;
  userId: string;
}

export const CommentsComponent = ({ post, userId }: CommentsComponentProps) => {
  const [pageNumberComment, setPageNumberComment] = useState<number>(1);

  const {
    data: commentList,
    loading: loadingComment,
    hasMore: hasMoreComment,
    refreshCommentClient,
    // getCommentWhenComment,
  } = useComment({
    pageNumber: pageNumberComment,
    postId: _.get(post, 'id') || '',
    visible: true,
  });

  return (
    <>
      {_.isEmpty(commentList) ? null : (
        <div className="sm:p-4 p-2.5 border-t border-gray-100 font-normal space-y-3 relative dark:border-slate-700/40">
          {commentList.map((comment) => {
            return (
              <SingleCommentComponent key={comment.id} comment={comment} refreshCommentClient={refreshCommentClient} />
            );
          })}
          {loadingComment ? <Loading /> : null}
          {hasMoreComment ? (
            <button
              className="text-sm text-slate-800 dark:text-slate-50 font-medium cursor-pointer"
              onClick={() => setPageNumberComment((prev: any) => prev + 1)}
            >
              Xem thêm
            </button>
          ) : null}
        </div>
      )}
      <CommentInputComponent
        setPageNumberComment={setPageNumberComment}
        postId={post?.id}
        refreshCommentClient={refreshCommentClient}
        userId={userId}
      />
    </>
  );
};
