import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import InputComponent from '../../components/input/input.component';
import * as yup from 'yup';
import ButtonPrimary from '../../components/button-refactor/button-primary';
import ErrorValidateComponent from '../../components/error-validate/error-validate.component';
import { authenticationServices } from '../../services/authentication.service';
import { routePaths } from '../../routes/routes-path';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { LocalStorageService } from '../../configuration';

const adminLoginSchema = yup.object({
  code: yup.string().required('code is required'),
});

const LoginByCodePage = () => {
  const navigate = useNavigate();
  const { email } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(adminLoginSchema),
  });

  const onSubmit = async (payload) => {
    try {
      const { accessToken, fingerprintHash, refreshToken } = await authenticationServices.verify2faCode(
        email,
        payload.code,
      );

      LocalStorageService.setItem('accessToken', accessToken);
      LocalStorageService.setItem('fingerprintHash', fingerprintHash);
      LocalStorageService.setItem('refreshToken', refreshToken);
      onLoginSuccess();
    } catch (error) {
      console.log('Admin Login Error:', error);
    }
  };

  const onLoginSuccess = async () => {
    // await dispatch(fetchProfile());
    navigate(routePaths.admin);
  };

  return (
    <div className="admin-login">
      <div className="flex justify-center items-center min-h-screen">
        <div className="w-full px-5 lg:px-0 mx-auto py-8">
          <div className="rounded-md bg-slate-50 dark:bg-zinc-800 max-w-md mx-auto p-6 shadow-lg">
            <h2 className="text-3xl font-bold mb-8 text-center">Đăng nhập Admin</h2>
            <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <label className="block font-medium text-sm mb-2 " htmlFor="code">
                  Code
                </label>
                <InputComponent register={register('code')} id="code" type="code" name="code" />
                <ErrorValidateComponent visible={!!errors.code}>{errors.code?.message}</ErrorValidateComponent>
              </div>
              <ButtonPrimary type="submit" className="w-full justify-center">
                Đăng nhập
              </ButtonPrimary>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginByCodePage;
