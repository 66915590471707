/* eslint-disable react/react-in-jsx-scope */
import { useMemo, useRef, useState } from 'react';
import { ModalProps } from './modal.create.post.type';
import { useUserProfile } from '../../../hooks';
import DialogComponent from '../../../components/dialog/dialog.component';
import TextareaAutosize from 'react-textarea-autosize';
import Select, { IOption } from '../../../select/select.component';
import toast from 'react-hot-toast';
import ButtonPrimary from '../../../components/button-refactor/button-primary';
import Avatar from '../../../components/avatar/avatar.component';
import _get from 'lodash/get';
import { RootState } from '../../../configuration';
import { useSelector } from 'react-redux';
import { FaLink } from 'react-icons/fa';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import Slider, { Settings } from 'react-slick';
import { PUBLIC_ENDPOINT } from '../../../constants/platform';
import { postService } from '../../../services/post.service';
import _ from 'lodash';

const ModalSharePost = (props: ModalProps) => {
  const { isOpen, reset, isEdit, onClose, onClearIdDetail, onOpen, onFetch, isGroup, post } = props;
  const shortId = _.get(post, 'shortId', '') || _.get(post, 'id', '');
  const [open, setOpen] = useState(isOpen);
  const [textAreaContent, setTextAreaContent] = useState<string>('');
  const { profile } = useUserProfile();
  const profileUser = useSelector((state: RootState) => state.features.auth.profile);
  const [audience, setAudience] = useState<number>(0);

  const handleClose = () => {
    reset?.(false);

    if (onOpen) {
      onOpen();
    }

    if (onClose) {
      onClose(true);
    }
    if (onClearIdDetail) {
      onClearIdDetail();
    }
  };

  const handleChangTextArea = (e) => {
    setTextAreaContent(e.target.value);
  };

  const handleSharePost = async () => {
    const shareLink = `${PUBLIC_ENDPOINT}/share/post/${shortId}`;
    const postId = post?.postType === 'share' ? _.get(post, 'sharedPost.id', '') : _.get(post, 'id', '');

    try {
      await postService.sharePost({
        userId: profileUser?.userId || '',
        postId,
        shareLink,
        isPublic: true,
        audienceType: audience,
        content: textAreaContent,
      });
      handleClose();
      toast.success('Chia sẻ bài viết thành công!');
    } catch (error) {
      console.log(error);
    }
  };

  const shareOptions = [
    {
      label: 'Công khai',
      value: 0,
    },
    {
      label: 'Bạn bè',
      value: 1,
    },
    {
      label: 'Chỉ mình tôi',
      value: 2,
    },
  ];

  return (
    <DialogComponent onClose={() => handleClose()} visible={open} placement="CENTER" title="Chia sẻ bài viết" size="lg">
      <div className="w-full pb-5">
        <div className="">
          <div className="flex py-2 items-center">
            <Avatar avatar={_get(profile, 'avatar', '')} label={_get(profile, 'fullname', '')} size="sm" />
            <div className="ml-2">
              <h3 className="text-slate-800 dark:text-slate-50 font-semibold text-sm">
                {_get(profileUser, 'fullname', '')}
              </h3>
              <Select
                className="cursor-pointer"
                options={shareOptions}
                onChange={(data: any) => {
                  setAudience(data.value);
                }}
                value={audience}
                placeholder="Chọn đối tượng bài viết"
              />
            </div>
          </div>

          <div className="w-full border border-solid rounded-lg bg-zinc-50 dark:bg-transparent">
            <TextareaAutosize
              minRows={6}
              className="outline-none  text-slate-800 dark:text-slate-50 bg-zinc-50 dark:bg-transparent
            rounded-lg w-full resize-none text-sm"
              onChange={(e) => handleChangTextArea(e)}
              value={textAreaContent}
              placeholder="Hãy nói gì đó về nội dung này..."
              autoFocus
            >
              {textAreaContent}
            </TextareaAutosize>
          </div>
          <ShareInto shortId={shortId} />
          <ButtonPrimary onClick={handleSharePost} className="w-full mt-4 flex justify-center">
            Chia sẻ ngay
          </ButtonPrimary>
        </div>
      </div>
    </DialogComponent>
  );
};

const ShareInto = ({ shortId }: { shortId?: string }) => {
  const sliderRef = useRef<Slider>(null);

  const ShareIntoOptions = [
    {
      label: 'Sao chép liên kết',
      value: 0,
      icon: <FaLink />,
      method: () => {
        const linkToCopy = `${PUBLIC_ENDPOINT}/share/post/${shortId}`;
        navigator.clipboard
          .writeText(linkToCopy)
          .then(() => {
            toast.success('Đã copy bài viết!', {
              position: 'top-center',
            });
          })
          .catch((err) => {
            toast.error('Failed to copy the link.');
            console.error('Clipboard error:', err);
          });
      },
    },
    // {
    //   label: 'Messenger',
    //   value: 2,
    //   icon: <FaFacebookMessenger />,
    //   method: () => {
    //     console.log('copy link');
    //   },
    // },
  ];

  const settings = useMemo<Settings>(
    () => ({
      dots: false,
      speed: 500,
      slidesToShow: ShareIntoOptions.length,
      slidesToScroll: ShareIntoOptions.length,
      arrows: false,
      centerMode: ShareIntoOptions.length > 6,
      centerPadding: ShareIntoOptions.length > 6 ? '30px' : '0px',
      className: 'center',
      infinite: ShareIntoOptions.length > 6,
      variableWidth: true,
    }),
    [],
  );

  return (
    <div className="mt-4">
      <h4 className="font-semibold">Chia sẻ lên</h4>
      <div className="flex gap-2">
        <div className="w-full flex items-center relative">
          {ShareIntoOptions.length > 6 && (
            <button
              className="text-black absolute z-10 rounded-full p-2 bg-slate-50 dark:bg-slate-800 "
              onClick={() => sliderRef.current?.slickPrev()}
            >
              <ChevronLeft size={24} />
            </button>
          )}
          <Slider ref={sliderRef} {...settings} className="w-full ">
            {ShareIntoOptions.map((option) => (
              <button
                onClick={option.method}
                key={option.value}
                className="outline-none h-16 hover:bg-slate-10
                0 dark:hover:bg-slate-800 rounded-lg"
              >
                <div className="w-16 m-auto flex flex-col items-center justify-center h-full">
                  <div>{option.icon}</div>
                  <div className="text-xs text-center">{option.label}</div>
                </div>
              </button>
            ))}
          </Slider>
          {ShareIntoOptions.length > 6 && (
            <button
              className="text-black absolute right-4 z-10 rounded-full p-2 bg-slate-50 dark:bg-slate-800 "
              onClick={() => sliderRef.current?.slickNext()}
            >
              <ChevronRight size={24} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalSharePost;
