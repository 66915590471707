import { useEffect, useState } from 'react';
import { IPostResponse } from '../../models';
import { postService } from '../../services/post.service';

export const useCategory = () => {
  const [postsByCategory, setPostsByCategory] = useState<IPostResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  const getPostByCategory = async (payload: {
    userId: string;
    categoryId: string;
    pageNumber: number;
    pageSize: number;
    search?: string;
    orderType?: number | null;
  }) => {
    try {
      setLoading(true);
      const data = await postService.getPostByCategory(payload);
      setPostsByCategory(data);
      setHasMore(data.hasMore);
    } catch (error) {
      console.error('Error fetching posts by hashtag:', error);
    } finally {
      setLoading(false);
    }
  };

  return { postsByCategory, getPostByCategory, loading, hasMore };
};
