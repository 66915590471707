import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getDashboardAdmin } from '../../../../api/admin/post.api';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register the required components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const HomeAdmin = (props) => {
  const [dashboardData, setDashboardData] = useState<any>();

  const getDashboardData = async () => {
    try {
      const { data } = await getDashboardAdmin();
      console.log('🚀 ~ getDashboardData ~ data:', data);
      setDashboardData(data);
    } catch {
      //
    } finally {
      //
    }
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  const data = {
    labels: ['Số lượng yêu cầu'],
    datasets: [
      {
        label: 'Content Creator',
        data: [dashboardData?.totalUserRequestCreator || 0],
        backgroundColor: '#FF6384',
        hoverBackgroundColor: '#FF6384',
      },
      {
        label: 'Chức năng donate',
        data: [dashboardData?.totalUserRequestDonate || 0],
        backgroundColor: '#36A2EB',
        hoverBackgroundColor: '#36A2EB',
      },
    ],
  };

  return (
    <div className="p-4 bg-gray-100 min-h-screen">
      <div className="container mx-auto">
        <h1 className="text-2xl font-bold mb-4">Trang chủ</h1>
        <div className="grid grid-cols-2 ">
          <div className="bg-white p-6 rounded-lg shadow-md ">
            <Bar data={data} />
          </div>
        </div>
      </div>
    </div>
  );
};

HomeAdmin.propTypes = {
  // Define your prop types here if needed
};

export default HomeAdmin;
