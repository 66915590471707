import React from 'react';
import { IPostCategory, IPostModel } from '../../../models';
import PostHeader from '../post-header.component';
import PostTypeImage from './post-type-image';
import PostTypeVideo from './post-type-video';

// import { Post } from '../post.component';

const PostTypeShare = ({ post, currentCategory }: { post: IPostModel; currentCategory?: IPostCategory }) => {
  const isPostImage = post?.postType === 'image';
  const isPostVideo = post?.postType === 'video';

  return (
    <div className="mt-2 px-4 py-2 rounded-lg text-sm font-medium border1 border-slate-300 dark:bg-dark2">
      <div className="flex gap-3">
        <PostHeader post={post} currentCategory={currentCategory} />
      </div>
      <p className="text-sm text-zinc-800 dark:text-zinc-50 my-2">{post?.content}</p>
      {(isPostImage || post?.postType === 'normal') && <PostTypeImage post={post} isShare />}
      {isPostVideo && <PostTypeVideo post={post} />}
    </div>
  );
};

export default PostTypeShare;
