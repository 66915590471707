import React, { useState } from 'react';
import { useReqDonate } from '../../../../hooks/admin/req-donate/use-req-donate';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import { approveReqDonateAPI, rejectReqDonateAPI } from '../../../../api/admin/req-donate.api';
import toast from 'react-hot-toast';

const ManagementRequestDonate = (props) => {
  const [isRefetch, setIsRefetch] = useState(false);
  const { data } = useReqDonate({ isRefetch });
  // handleApprove
  const handleApprove = async (id) => {
    try {
      await approveReqDonateAPI(id);
      setIsRefetch(!isRefetch);
      toast.success('Duyệt thành công');
    } catch (error) {
      toast.error('Duyệt thất bại');
    }
  };

  // handleReject
  const handleReject = async (id) => {
    try {
      await rejectReqDonateAPI(id);
      setIsRefetch(!isRefetch);
      toast.success('Từ chối thành công');
    } catch (error) {
      toast.error('Từ chối thất bại');
    }
  };

  const columns = [
    {
      title: 'Tên',
      dataIndex: 'fullName',
      key: 'fullName',
      render: (text, record) => <div>{record?.user?.fullName}</div>,
    },
    {
      title: 'Ngân hàng',
      dataIndex: 'bankName',
      key: 'bankName',
      render: (text, record) => (
        <div>
          <div>{record.bankName}</div>
          <div>{record.bankAccount}</div>
        </div>
      ),
    },
    {
      title: 'Link Reference',
      dataIndex: 'referenceLinks',
      key: 'referenceLinks',
      render: (text, record) => (
        <Link to={record.referenceLinks} target="_blank">
          {record.referenceLinks}
        </Link>
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (text, record) => (
        <div>
          {record.status === 'Pending' ? (
            <span className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
              Đang chờ duyệt
            </span>
          ) : record.status === 'Approved' ? (
            <span className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-green-100 text-green-800">
              Yêu cầu được duyệt
            </span>
          ) : (
            <span className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-red-100 text-red-800">
              Từ chối yêu cầu
            </span>
          )}
        </div>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) =>
        record.status === 'Pending' && (
          <div className="flex">
            <button
              className="px-3 py-1 bg-green-500 text-white rounded-lg mr-2"
              onClick={() => handleApprove(record?.user?.id)}
            >
              Duyệt
            </button>
            <button
              className="px-3 py-1 bg-red-500 text-white rounded-lg"
              onClick={() => handleReject(record?.user?.id)}
            >
              Từ chối
            </button>
          </div>
        ),
    },
  ];
  return (
    <>
      {' '}
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">Yêu cầu mở donate cho user</h1>
      </div>
      <div className="bg-white p-4 rounded-lg ">
        <Table dataSource={data} columns={columns} />
      </div>
      ;
    </>
  );
};

export default ManagementRequestDonate;
