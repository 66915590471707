import { API } from '../api';
import { ICreatorResponse } from '../types/creator';
import { ensureMinDelay } from '../utils';

const endPoints = {
  creatorSuggestion: 'https://api.beta.cab.vn/v1/user-service/users/get-creator-suggestion',
  hiddenSuggestion: 'https://api.beta.cab.vn/v1/user-service/users/hidden-suggestion',
  getCreatorMatchInterestUser: 'https://api.beta.cab.vn/v1/user-service/users/get-creator-match-interest-user',
};

const getCreatorSuggestion = () => {
  return new Promise<ICreatorResponse>((resolve, reject) => {
    ensureMinDelay(API.get<ICreatorResponse>(endPoints.creatorSuggestion))
      .then((data) => {
        resolve(data.data);
      })
      .catch(reject);
  });
};

const removeCreatorSuggestion = (params: { userId: string }) => {
  return new Promise<void>((resolve, reject) => {
    ensureMinDelay(API.post<void>(`${endPoints.hiddenSuggestion}/${params.userId}`))
      .then((data) => {
        resolve(data.data);
      })
      .catch(reject);
  });
};

const getCreatorMatchInterestUser = () => {
  return new Promise<ICreatorResponse>((resolve, reject) => {
    ensureMinDelay(API.get<ICreatorResponse>(endPoints.getCreatorMatchInterestUser))
      .then((data) => {
        resolve(data.data);
      })
      .catch(reject);
  });
};

const creatorService = { getCreatorSuggestion, removeCreatorSuggestion, getCreatorMatchInterestUser };

export default creatorService;
