import { MoreHorizontal, Edit, Save, EyeOff } from 'lucide-react';
import React, { useState } from 'react';
import { ReportPostDialog } from '../report-post';
import useBookmark from '../../hooks/use-bookmark/useBookMark';
import { IPostModel } from '../../models';
import DeletePost from '../delete-post/delete-post.component';
import toast from 'react-hot-toast';
import ModalCreatePost from '../../pages/home/components/modal.create.post';

interface IPostMenuProps {
  post: IPostModel;
  userId: string;
  removePost: (id: string) => void;
  setIsHiddenPost: (id: string) => void;
}

const PostMenu = ({ post, userId, removePost, setIsHiddenPost }: IPostMenuProps) => {
  const [isShowMoreMenu, setShowMoreMenu] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [isClose, setIsClose] = useState<boolean>(true);
  const [idDetail, setIdDetail] = useState<string>('');
  const { toggleBookmark } = useBookmark();

  const handleEditPost = (e, id) => {
    setOpen(true);
    setShowMoreMenu(false);
    setIsClose(false);
    e.stopPropagation();
    setIdDetail(id);
  };

  const handleClearIdDetail = () => {
    setIdDetail('');
  };

  const handleOpen = () => {
    setOpen(false);
  };

  const handleClose = (data) => {
    setIsClose(data);
  };

  return (
    <>
      <div className="relative flex items-start">
        <button className="button-icon w-8 h-8" type="button" onClick={() => setShowMoreMenu((prev) => !prev)}>
          <MoreHorizontal size={16} />
        </button>

        {isShowMoreMenu && (
          <div className="absolute top-5 right-0 rounded-md w-max bg-white dark:bg-black text-slate-800 dark:text-slate-50 shadow p-1">
            <ul>
              {post?.userId === userId && (
                <>
                  <li>
                    <button
                      className="rounded-md flex items-center space-x-2 w-full p-2 hover:bg-gray-1 dark:hover:bg-gray-10 "
                      onClick={(e) => handleEditPost(e, post?.id)}
                    >
                      <Edit size={14} />
                      <span className="text-xs">Chỉnh sửa bài viết</span>
                    </button>
                    <div className="text-xs text-center font-medium " />
                  </li>
                  <li>
                    <DeletePost removePost={removePost} postId={post?.id} />
                    <div className="text-xs text-center font-medium " />
                  </li>
                </>
              )}
              <li>
                <button className="rounded-md flex items-center space-x-2 w-full p-2 hover:bg-gray-1 dark:hover:bg-gray-10 ">
                  <Save size={14} />
                  <span
                    className="text-xs"
                    onClick={() => {
                      post?.id && toggleBookmark(post?.id);
                      setShowMoreMenu(false);
                      toast.success('Đã lưu bài viết');
                    }}
                  >
                    Lưu bài
                  </span>
                </button>
                <div className="text-xs text-center font-medium " />
              </li>
              <li>
                <button
                  className="rounded-md flex items-center space-x-2 w-full p-2 hover:bg-gray-1 dark:hover:bg-gray-10"
                  onClick={() => {
                    post?.id && setIsHiddenPost(post?.id);
                  }}
                >
                  <EyeOff size={14} />
                  <span className="text-xs">Ẩn bài viết</span>
                </button>
              </li>
              <li>
                <ReportPostDialog postId={post?.id} onwerPostReportId={post?.userId} />
              </li>
            </ul>
          </div>
        )}
      </div>
      {open && (
        <ModalCreatePost
          onClearIdDetail={() => handleClearIdDetail()}
          isOpen={open}
          onOpen={handleOpen}
          onClose={handleClose}
          isClose={isClose}
          isEdit={idDetail}
        />
      )}
    </>
  );
};

export default PostMenu;
