import React from 'react';
import { IPostCategory, IPostModel } from '../../models';
import { Link, NavLink } from 'react-router-dom';
import Avatar from '../avatar/avatar.component';
import _ from 'lodash';
import { useCategories } from '../../hooks/use-categories/useCategories';
import dayjs from 'dayjs';
import { MdGroups, MdPublic, MdAccountCircle } from 'react-icons/md';
import { FaUserAlt } from 'react-icons/fa';

const PostHeader = ({ post, currentCategory }: { post?: IPostModel; currentCategory?: IPostCategory }) => {
  const isPublic = post?.sharedPostAudienceType === 0;
  const isFriend = post?.sharedPostAudienceType === 1;
  const isOnlyMe = post?.sharedPostAudienceType === 2;
  return (
    <>
      <Link className=" cursor-pointer" to={`/user/${post?.username}`}>
        <Avatar avatar={_.get(post, 'userAvatar', '')} label={_.get(post, 'userFullName', '')} size="sm" />
      </Link>
      <div className="flex-1">
        <div>
          <Link
            className="inline-block text-slate-800 dark:text-slate-50 text-sm font-bold mb-2"
            to={`/user/${post?.username}`}
          >
            {post?.userFullName ?? 'Người dùng không xác định'}
          </Link>
          {post?.postType === 'share' && <span className="font-light ml-1">đã chia sẻ 1 bài viết</span>}
          <div className="flex items-center space-x-2 text-xs">
            <NavLink
              to={`/category/${post?.categoryId}`}
              className={({ isActive, isPending }) => (isActive ? 'pointer-events-none' : '')}
            >
              <span className="text-branding font-semibold mr-2">{_.get(currentCategory, 'name', '')}</span>
            </NavLink>
            <span>•</span>
            <span>{dayjs.utc(post?.createdAt).fromNow()} trước</span>
            {post?.postType === 'share' && (
              <span className="ml-1">
                {isPublic && <MdPublic size={16} />} {isFriend && <MdGroups size={20} />} {isOnlyMe && <FaUserAlt />}
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PostHeader;
