import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import React, { useMemo, useRef, useState, useCallback, useEffect } from 'react';
import { IPostModel, IReplyCommentParams } from '../../models';
import DialogComponent from '../dialog/dialog.component';
import Slider, { Settings } from 'react-slick';
import { ChevronLeft, ChevronRight, MoreHorizontal, Send, Smile } from 'lucide-react';
import { LazyLoadImage } from '../lazy-load-image';
import Avatar from '../avatar/avatar.component';
import { Link, useNavigate } from 'react-router-dom';
import { useComment } from '../../hooks/post/useComment';
import { useReplyComment } from '../../hooks/post/useReplyComment';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { postService } from '../../services/post.service';
import Loading from '../loading/loading.component';
import _get from 'lodash/get';
import { sendCommentOfPost } from '../../api/posts/fetch-post.api';
import { useUserProfile } from '../../hooks/use-user-profile';
import classNames from 'classnames';
import { HiThumbDown, HiThumbUp } from 'react-icons/hi';
import InfiniteScroll from 'react-infinite-scroll-component';
import _ from 'lodash';

dayjs.extend(utc);

interface PreviewPostDialogProps {
  post: IPostModel;
  visible: boolean;
  initialSlide: number;
  onclose: () => void;
}

const PreviewPostDialog: React.FC<PreviewPostDialogProps> = ({ visible, post, initialSlide, onclose }) => {
  const sliderRef = useRef<Slider>(null);
  const [pageNumberComment, setPageNumberComment] = useState(1);
  const [pageNumberReplyComment, setPageNumberReplyComment] = useState(1);
  const [commentValue, setCommentValue] = useState('');
  const [replyCommentValue, setReplyCommentValue] = useState('');
  const [isShowReply, setIsShowReply] = useState(false);
  const [replyCommentId, setReplyCommentId] = useState('');
  const [isReplyComment, setIsReplyComment] = useState(false);
  const [commentId, setCommentId] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showReplyEmojiPicker, setShowReplyEmojiPicker] = useState(false);
  const [showReplyEmojiPickerOnTop, setShowReplyEmojiPickerOnTop] = useState(true);
  const navigate = useNavigate();
  const replyInputRef = useRef<HTMLDivElement>(null);

  const { profile } = useUserProfile();
  const userId = useMemo(() => profile?.userId, [profile]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest('.emoji-picker-container')) {
        setShowEmojiPicker(false);
        setShowReplyEmojiPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (replyInputRef.current) {
      const rect = replyInputRef.current.getBoundingClientRect();
      const spaceAbove = rect.top;
      const spaceBelow = window.innerHeight - rect.bottom;
      setShowReplyEmojiPickerOnTop(spaceBelow < 350); // 350px là chiều cao ước tính của emoji picker
    }
  }, [isReplyComment]);

  const {
    data: commentList,
    loading: loadingComment,
    refreshCommentClient,
    getCommentWhenComment,
    hasMore,
    getComment,
  } = useComment({
    pageNumber: pageNumberComment,
    pageSize: 10,
    postId: _get(post, 'id', ''),
    visible: true,
  });

  const { data: replyCommentList, getReplyCommentWhenReply } = useReplyComment({
    pageNumber: pageNumberReplyComment,
    commentId: replyCommentId,
    visible: isShowReply,
  });

  const images = useMemo(() => post.postImageResponses.map((image) => image), [post]);

  const settings = useMemo<Settings>(
    () => ({
      dots: false,
      infinite: post?.postImageResponses?.length > 1,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      initialSlide,
    }),
    [initialSlide],
  );

  const handleSendComment = useCallback(async () => {
    if (!commentValue?.trim()) return;

    const res = await sendCommentOfPost({ userId, postId: post?.id, content: commentValue });
    if (res) {
      await getCommentWhenComment();
      setCommentValue('');
    }
  }, [commentValue, userId, post?.id, getCommentWhenComment]);

  const handleCreateReplyComments = useCallback(
    (id: string) => {
      setIsReplyComment((prev) => {
        if (!commentId) return true;
        if (id === commentId) return !prev;
        return true;
      });
      setCommentId(id);
    },
    [commentId],
  );

  const handleSendRelyComment = useCallback(
    async (commentId: string) => {
      if (!replyCommentValue?.trim()) return;

      setReplyCommentId(commentId);

      try {
        const payload: IReplyCommentParams = {
          content: replyCommentValue,
          replyToCommentId: commentId,
        };

        await postService.replyComment(payload);
        setIsShowReply(true);
        setReplyCommentValue('');

        if (isShowReply) {
          await getReplyCommentWhenReply(commentId);
        }

        await refreshCommentClient();
      } catch (error) {
        console.error('Error sending reply comment:', error);
      }
    },
    [replyCommentValue, isShowReply, getReplyCommentWhenReply, refreshCommentClient],
  );

  const toggleLike = useCallback(
    async (id: string, type: number) => {
      await postService.likeComment({ commentId: id, type });
      await refreshCommentClient();
    },
    [refreshCommentClient],
  );

  const onEmojiSelect = useCallback((emoji: any) => {
    setCommentValue((prev) => prev + emoji.native);
    setShowEmojiPicker(false);
  }, []);

  const onReplyEmojiSelect = useCallback((emoji: any) => {
    setReplyCommentValue((prev) => prev + emoji.native);
    setShowReplyEmojiPicker(false);
  }, []);

  const fetchMoreData = useCallback(() => {
    if (!loadingComment && hasMore) {
      setPageNumberComment((prev) => prev + 1);
      getComment({
        pageNumber: pageNumberComment + 1,
        pageSize: 10,
        postId: post.id,
        pagingState: '',
      });
    }
  }, [pageNumberComment, loadingComment, hasMore, post.id, getComment]);

  if (!visible) return null;

  const hasImage = !_.isEmpty(post?.postImageResponses);

  return (
    <div>
      <DialogComponent visible={visible} onClose={onclose} size={hasImage ? '4xl' : 'lg'}>
        <div className="flex h-[85vh]">
          {hasImage && (
            <>
              {/* Left side - Image slider */}
              <div className="w-[60%] bg-gray-100 dark:bg-gray-800  flex items-center relative">
                <button
                  className="text-black absolute left-4 z-10 hover:bg-white/10 rounded-full p-2"
                  onClick={() => sliderRef.current?.slickPrev()}
                >
                  <ChevronLeft size={24} />
                </button>
                {post?.postImageResponses?.length > 1 ? (
                  <Slider ref={sliderRef} {...settings} className="w-full h-full">
                    {post?.postImageResponses?.map((image, index) => (
                      <div key={image.imageId} className="outline-none h-full">
                        <LazyLoadImage
                          className="w-full h-full object-contain"
                          src={image.url}
                          alt={`Slide ${index + 1}`}
                        />
                      </div>
                    ))}
                  </Slider>
                ) : (
                  post?.postImageResponses?.map((image, index) => (
                    <div key={image.imageId} className="w-full max-h-[85vh] overflow-y-auto">
                      <img src={image.url} alt={`Slide ${index + 1}`} className="w-full h-full object-contain" />
                    </div>
                  ))
                )}

                <button
                  className="text-black absolute right-4 z-10 hover:bg-white/10 rounded-full p-2"
                  onClick={() => sliderRef.current?.slickNext()}
                >
                  <ChevronRight size={24} />
                </button>
              </div>
            </>
          )}

          {/* Right side - Comments */}
          <div className={`bg-white dark:bg-zinc-900 flex flex-col ${hasImage ? 'w-[40%]' : 'w-full'}`}>
            {/* Header */}
            <div className=" p-4 border-b border-gray-200 dark:border-zinc-700 flex items-center justify-between">
              <div className="flex items-center gap-3  w-full">
                <Link to={`/user/${post.username}`}>
                  <Avatar avatar={post.userAvatar} size="sm" label={post.userFullName} />
                </Link>
                <div className="flex flex-col gap-2 w-full">
                  <div className="flex justify-between gap-2">
                    <Link to={`/user/${post.username}`} className="font-medium text-slate-800 dark:text-white">
                      {post.userFullName}
                    </Link>
                    <button className="text-slate-600 dark:text-slate-300">
                      <MoreHorizontal size={20} />
                    </button>
                  </div>
                  <div className="flex items-center space-x-2 text-xs text-gray-500">
                    <span className="text-branding font-semibold">{_.get(post, 'categoryName', '')}</span>
                    <span>•</span>
                    <span>{dayjs.utc(post?.createdAt).fromNow()} trước</span>
                  </div>
                  <div>
                    <p className="text-sm text-zinc-800 dark:text-zinc-50 line-clamp-2">{post?.content}</p>
                    {post?.hashtags && (
                      <div className="flex flex-wrap gap-1 mt-2">
                        {post.hashtags.split(' ').map((tag, index) => (
                          <span
                            key={index}
                            className="text-violet-800 dark:text-violet-500 text-sm hover:underline cursor-pointer"
                          >
                            #{tag}
                          </span>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Comment list */}
            <div id="scrollableDiv" className="flex-1 overflow-y-auto px-4">
              <InfiniteScroll
                dataLength={commentList.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={<Loading />}
                scrollableTarget="scrollableDiv"
              >
                {commentList.map((comment) => (
                  <div key={comment.id} className="py-4 border-b border-gray-100 dark:border-zinc-800">
                    <div className="flex gap-3">
                      <Link to={`/user/${comment.userId}`}>
                        <Avatar avatar={comment.avatar} size="xs" label={comment.userFullName} />
                      </Link>
                      <div className="flex-1">
                        <div className="bg-gray-100 dark:bg-zinc-800 rounded-2xl px-4 py-2">
                          <Link to={`/user/${comment.userId}`} className="font-medium text-slate-800 dark:text-white">
                            {comment.userFullName}
                          </Link>
                          <p className="text-sm text-slate-600 dark:text-slate-300 mt-1">{comment.content}</p>
                        </div>

                        <div className="flex items-center gap-4 mt-2 text-xs">
                          {!comment.currentUserHasUnlike && (
                            <button
                              className={classNames('font-medium', {
                                'text-blue-500': comment.currentUserHasLike,
                                'text-slate-500': !comment.currentUserHasLike,
                              })}
                              onClick={() => toggleLike(comment.id, 1)}
                            >
                              <HiThumbUp className="inline mr-1" size={14} />
                              Thích
                            </button>
                          )}

                          {!comment.currentUserHasLike && (
                            <button
                              className={classNames('font-medium', {
                                'text-red-500': comment.currentUserHasUnlike,
                                'text-slate-500': !comment.currentUserHasUnlike,
                              })}
                              onClick={() => toggleLike(comment.id, -1)}
                            >
                              <HiThumbDown className="inline mr-1" size={14} />
                              Không thích
                            </button>
                          )}

                          <button
                            className="font-medium text-slate-500"
                            onClick={() => handleCreateReplyComments(comment.id)}
                          >
                            Phản hồi
                          </button>

                          <span className="text-slate-500">{dayjs.utc(comment.createdAt).local().fromNow()}</span>

                          {comment.totalReply > 0 && (
                            <button
                              className="font-medium text-slate-500"
                              onClick={() => {
                                setIsShowReply((prev) => !prev);
                                setReplyCommentId(comment.id);
                              }}
                            >
                              {isShowReply && comment.id === replyCommentId
                                ? 'Thu gọn'
                                : `Xem ${comment.totalReply} phản hồi`}
                            </button>
                          )}
                        </div>

                        {isShowReply && comment.id === replyCommentId && (
                          <div className="ml-8 mt-4 space-y-4">
                            {replyCommentList?.map((reply) => (
                              <div key={reply.id} className="flex gap-3">
                                <Link to={`/user/${reply.userId}`}>
                                  <Avatar avatar={reply.avatar} size="xs" label={reply.userFullName} />
                                </Link>
                                <div className="flex-1">
                                  <div className="bg-gray-100 dark:bg-zinc-800 rounded-2xl px-4 py-2">
                                    <Link
                                      to={`/user/${reply.userId}`}
                                      className="font-medium text-slate-800 dark:text-white"
                                    >
                                      {reply.userFullName}
                                    </Link>
                                    <p className="text-sm text-slate-600 dark:text-slate-300 mt-1">{reply.content}</p>
                                  </div>
                                  <span className="text-xs text-slate-500 mt-2 block">
                                    {dayjs.utc(reply.createdAt).local().fromNow()}
                                  </span>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}

                        {isReplyComment && commentId === comment.id && (
                          <div className="mt-4 flex items-center gap-2">
                            <div ref={replyInputRef} className="flex-1 relative">
                              <input
                                type="text"
                                placeholder="Viết phản hồi..."
                                className="w-full bg-gray-100 dark:bg-zinc-800 rounded-full px-4 py-2 text-sm outline-none pr-10"
                                value={replyCommentValue}
                                onChange={(e) => setReplyCommentValue(e.target.value)}
                              />
                              <button
                                onClick={() => setShowReplyEmojiPicker(!showReplyEmojiPicker)}
                                className="absolute right-3 top-1/2 -translate-y-1/2"
                              >
                                <Smile size={20} className="text-slate-500" />
                              </button>
                              {showReplyEmojiPicker && (
                                <div
                                  className={classNames('absolute z-[1] right-0 mb-2 emoji-picker-container', {
                                    'bottom-full': showReplyEmojiPickerOnTop,
                                    'top-full mt-2': !showReplyEmojiPickerOnTop,
                                  })}
                                >
                                  <Picker data={data} onEmojiSelect={onReplyEmojiSelect} />
                                </div>
                              )}
                            </div>
                            <button
                              onClick={() => handleSendRelyComment(comment.id)}
                              className="text-blue-500 hover:text-blue-600 disabled:opacity-50"
                              disabled={!replyCommentValue}
                            >
                              <Send size={16} />
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </InfiniteScroll>
            </div>

            {/* Comment input */}
            <div className="p-4 border-t border-gray-200 dark:border-zinc-700">
              <div className="flex items-center gap-2">
                <div className="flex-1 relative">
                  <input
                    type="text"
                    placeholder="Thêm bình luận..."
                    className="w-full bg-gray-100 dark:bg-zinc-800 rounded-full px-4 py-2 text-sm outline-none pr-10"
                    value={commentValue}
                    onChange={(e) => setCommentValue(e.target.value)}
                  />
                  <button
                    onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                    className="absolute right-3 top-1/2 -translate-y-1/2"
                  >
                    <Smile size={20} className="text-slate-500" />
                  </button>
                  {showEmojiPicker && (
                    <div className="absolute bottom-full right-0 mb-2 emoji-picker-container">
                      <Picker data={data} onEmojiSelect={onEmojiSelect} />
                    </div>
                  )}
                </div>
                <button
                  onClick={handleSendComment}
                  className="text-blue-500 hover:text-blue-600 disabled:opacity-50"
                  disabled={!commentValue}
                >
                  <Send size={20} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </DialogComponent>
    </div>
  );
};

export default PreviewPostDialog;
