import React, { useState } from 'react';
import { IUserProfile } from '../../../models';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import Avatar from '../../../components/avatar/avatar.component';
import EditProfile from './edit-profile';
import { IDatingProfileModel } from '../../../models/dating.model';
import { IOption } from '../../../select/select.component';
import { fetchUrlImages } from '../../../api';
import { datingService } from '../../../services/dating.service';
import toast from 'react-hot-toast';
import HeartIcon from '../../../assets/icons/match.png';
import MessageIcon from '../../../assets/icons/message.png';
import ProfileIcon from '../../../assets/icons/profile-dating.png';
import AlbumIcon from '../../../assets/icons/album.png';
import SettingIcon from '../../../assets/icons/setting.png';
import FireMatch from '../../../assets/icons/fire-match.png';
import ChatIcon from '../../../assets/icons/dating-chat.png';
import DialogComponent from '../../../components/dialog/dialog.component';

interface ProfileBannerProps {
  profile?: IDatingProfileModel;
  changeTab?: any;
  tabActive?: any;
  cities?: IOption[];
  getDatingProfile?: () => void;
  totalMatchRequests?: number;
}

const Icon = ({ src, className = 'w-8 rounded-full' }: { src: string; className?: string }) => {
  return <img src={src} alt="feeds" className={className} />;
};

const Tabs = [
  {
    label: 'Khám phá',
    value: 'connection',
    icon: <Icon src={HeartIcon} />,
  },
  {
    label: 'Kết nối ',
    value: 'requests',
    icon: <Icon src={MessageIcon} />,
  },
  {
    label: 'Profile',
    value: 'profile',
    icon: <Icon src={ProfileIcon} />,
  },
  {
    label: 'Album ảnh',
    value: 'album',
    icon: <Icon src={AlbumIcon} />,
  },
  {
    label: 'Chat',
    value: 'chat',
    icon: <Icon src={ChatIcon} />,
  },
  {
    label: 'Cập nhật thông tin',
    value: 'update_profile',
    icon: <Icon src={SettingIcon} />,
  },
];

const ProfileBanner = ({
  profile,
  changeTab,
  tabActive,
  cities,
  getDatingProfile,
  totalMatchRequests,
}: ProfileBannerProps) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState<boolean>(false);
  const [isNotValidProfile, setIsNotValidProfile] = useState<boolean>(false);
  const onUploadAvatar = async (files: any) => {
    try {
      const file = files[0];
      const formData = new FormData();
      formData.append('type', file);
      const res = await fetchUrlImages(formData);
      console.log('❤️ ~ onUploadAvatar ~ res:', res);
      profile && (profile.avatar = res[0].url);
      profile && (profile.albumUrls = [res[0].url]);
      await datingService.updateDatingProfile(profile as IDatingProfileModel);
      toast.success('Cập nhật thông tin thành công');
      getDatingProfile && getDatingProfile();
    } catch (error) {
      toast.error('Không thể cập nhật ảnh đại diện');
      console.error('Error uploading avatar:', error);
    }
  };

  const onTabClick = (value: string) => {
    console.log('❤️ ~ onTabClick ~ value:', value);
    if (value === 'update_profile') {
      setVisible(true);
      return;
    }
    if (!profile?.profileId) {
      setIsNotValidProfile(true);
      return;
    }
    changeTab && changeTab(value);
  };

  return (
    <div className="bg-white lg:rounded-2xl shadow dark:bg-dark2">
      <EditProfile
        getDatingProfile={getDatingProfile}
        cities={cities}
        profile={profile}
        visible={visible}
        setVisible={setVisible}
      />
      <div className="relative overflow-hidden w-full lg:h-56 h-48">
        <img
          src={
            profile?.coverImage ||
            'https://wallpapers.com/images/hd/pink-love-1920-x-1080-wallpaper-mzjkazybbea7amzt.jpg'
          }
          alt=""
          className="h-full w-full object-cover inset-0 rounded-2xl"
        />
        <div className="w-full bottom-0 absolute left-0 bg-gradient-to-t from-black/60 pt-20 z-10" />
      </div>
      <div className="border-b border-solid border-slate-300">
        <div className="flex flex-col justify-center  md:items-center lg:-mt-50 -mt-28">
          <div className="relative z-10">
            <Avatar
              avatar={_.get(profile, 'avatar', '')}
              label={_.get(profile, 'nickname', '')}
              size="xxl"
              uploaded
              onUploadAvatar={onUploadAvatar}
              // onUploadSuccess={onUpdateSuccess}
              preview={false}
            />
          </div>
          <div
            className={`mt-2 px-3 text-base  dark:text-white flex items-center ${
              _.get(profile, 'nickname') ? 'font-bold text-black md:text-3xl' : 'md:text-1xl text-grey-400'
            }`}
          >
            {_.get(profile, 'nickname', '( Vui lòng cập nhật tên hiển thị )')}
          </div>
          <ul className="flex items-center justify-center mt-1">
            <li className="m-2">
              <h4 className="font-bold text-sm text-center flex flex-col items-center">
                <div className="flex items-center gap-1">
                  <Icon src={FireMatch} className="w-4" />
                  <span>{0}</span>
                </div>
                <span className="text-[10px] font-medium mt-1 text-gray-500">Lượt quẹt</span>
              </h4>
            </li>
            <li className="m-2">
              <h4 className="font-bold text-sm text-center flex flex-col items-center">
                <div className="flex items-center gap-1">
                  <span>{0}</span>
                </div>
                <span className="text-[10px] font-medium mt-1 text-gray-500">Đã kết nối</span>
              </h4>
            </li>
            <li className="m-2">
              <h4 className="font-bold text-sm text-center flex flex-col items-center">
                <div className="flex items-center gap-1">
                  <span>{0}</span>
                </div>
                <span className="text-[10px] font-medium mt-1 text-gray-500">Đã gửi kết nối</span>
              </h4>
            </li>
            <li className="m-2">
              <h4 className="font-bold text-sm text-center flex flex-col items-center">
                <div className="flex items-center gap-1">
                  <span>{0}</span>
                </div>
                <span className="text-[10px] font-medium mt-1 text-gray-500">Số tiền</span>
              </h4>
            </li>
          </ul>
        </div>
      </div>
      <div className=" max-h-[520px] overflow-y-auto ">
        <div className="min-h-96">
          <TabsComponent tabActive={tabActive} changeTab={onTabClick} totalMatchRequests={totalMatchRequests || 0} />
        </div>
      </div>
      <DialogComponent
        size="xl"
        visible={isNotValidProfile}
        onClose={() => setIsNotValidProfile(false)}
        title="Cập nhật thông tin"
        placement="CENTER"
      >
        Vui lòng cập nhật profile để sử dụng tính năng
      </DialogComponent>
    </div>
  );
};

const TabsComponent = ({
  tabActive,
  changeTab,
  totalMatchRequests,
}: {
  tabActive: string;
  changeTab: any;
  totalMatchRequests: number;
}) => {
  return (
    <nav className="bg-white flex flex-col gap-0.5 w-full  -mb-px  text-gray-600 font-medium text-[15px]  dark:text-white max-md:w-full max-md:overflow-x-auto">
      {Tabs.map((tab) => (
        <div
          key={tab.value}
          className={` flex items-center cursor-pointer   px-3 py-3 ${
            tabActive === tab.value && 'bg-white font-bold'
          } `}
          onClick={() => changeTab && changeTab(tab.value)}
        >
          <div className="flex items-center gap-2">
            {tab.icon}
            {tab.label} {tab.value === 'requests' && <span className="text-[#E31777]">({totalMatchRequests})</span>}
          </div>
        </div>
      ))}
    </nav>
  );
};

export default ProfileBanner;
