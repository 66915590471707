import React, { useState } from 'react';
import { useUserHook } from '../../../../hooks/admin/user/use-user';
import { Button, Dropdown, Modal, Space, Switch, Table } from 'antd';
import { FaCheck, FaCheckCircle, FaChevronDown, FaClock, FaEdit, FaEye } from 'react-icons/fa';
import { IoClose, IoCloseOutline } from 'react-icons/io5';
import { MdBlock } from 'react-icons/md';
import { RiRadioButtonLine } from 'react-icons/ri';
import { blockUser, confirmCreator, deleteUser, toggleChangeReciveDonateAPI } from '../../../../api/admin/user.api';
import toast from 'react-hot-toast';
import { CreateUserModal } from '../../../register/create-form-user';

const ManagementUser = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [isRefetch, setIsRefetch] = useState(false);
  const { data } = useUserHook({ pageNumber, isRefetch });
  const handleBlock = async (userId: string) => {
    try {
      await blockUser(userId);
      setIsRefetch(!isRefetch);
      toast.success('Block user successfully');
    } catch (error) {
      toast.error('Block user failed');
    }
  };

  const handleConfirmCreator = async (userId: string) => {
    try {
      await confirmCreator(userId);
      setIsRefetch(!isRefetch);
      toast.success('Confirm creator successfully');
    } catch (error) {
      toast.error('Confirm creator failed');
    }
  };

  const handleDelete = async (userId: string) => {
    try {
      await deleteUser(userId);
      setIsRefetch(!isRefetch);
      toast.success('Delete user successfully');
    } catch (error) {
      toast.error('Delete user failed');
    }
  };
  // toggleChangeReciveDonateAPI
  const handleToggleChangeReciveDonate = async (id: string) => {
    try {
      await toggleChangeReciveDonateAPI(id);
      setIsRefetch(!isRefetch);
      toast.success('Change receive donate successfully');
    } catch (error) {
      toast.error('Change receive donate failed');
    }
  };

  const columns = [
    {
      title: 'Tên',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'Email / Tên tài khoản',
      dataIndex: 'email',
      key: 'email',
      render: (_, record) => {
        return (
          <div>
            <div>{record.email}</div>
            <div>{record.userName}</div>
          </div>
        );
      },
    },
    {
      title: 'Xác minh email',
      dataIndex: 'isVerifyEmail',
      key: 'isVerifyEmail',
      sorter: (a, b) => a.isVerifyEmail - b.isVerifyEmail,
      render: (isVerifyEmail) => {
        return (
          <div>
            {isVerifyEmail ? (
              <div className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-green-100 text-green-800">
                <FaCheck className="mr-1" />
                Đã xác minh
              </div>
            ) : (
              <div className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-red-100 text-red-800">
                <IoClose className="mr-1" />
                Chưa xác minh
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: 'Request Creator',
      dataIndex: 'isRequestCreator',
      key: 'isRequestCreator',
      sorter: (a, b) => a.isRequestCreator - b.isRequestCreator,
      render: (isRequestCreator, record) => {
        return (
          <div>
            {record?.userType === 'NORMAL' && isRequestCreator ? (
              <div className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                <FaClock className="mr-1" />
                Đang chờ duyệt
              </div>
            ) : record?.userType === 'CONTENT_CREATOR' ? (
              <div className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-green-100 text-green-800">
                Đã là creator
              </div>
            ) : (
              <div className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-red-100 text-red-800">
                <IoClose className="mr-1" />
                Chưa yêu cầu
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: 'Donate',
      dataIndex: 'canReceiveDonation',
      key: 'canReceiveDonation',
      sorter: (a, b) => a.canReceiveDonation - b.canReceiveDonation,
      render: (canReceiveDonation) => {
        return (
          <div>
            {canReceiveDonation ? (
              <div className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-green-100 text-green-800">
                <FaCheck className="mr-1" />
                Đã kích hoạt
              </div>
            ) : (
              <div className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-red-100 text-red-800">
                <IoClose className="mr-1" />
                Chưa kích hoạt
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'isBlock',
      key: 'isBlock',
      sorter: (a, b) => a.isBlock - b.isBlock,
      render: (isBlock) => {
        return (
          <div>
            {!isBlock ? (
              <div className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-green-100 text-green-800">
                <RiRadioButtonLine className="mr-1" />
                Đang hoạt động
              </div>
            ) : (
              <div className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-red-100 text-red-800">
                <MdBlock className="mr-1" />
                Đã khoá
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: 'Action',
      key: 'operation',
      render: (record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: [
                {
                  key: '1',
                  label: !record.isBlock ? 'Khoá tài khoản' : 'Mở khoá',
                  icon: <MdBlock className="mr-2" />,
                  danger: true,
                  onClick: () => handleBlock(record.id),
                },
                {
                  key: '2',
                  label:
                    record?.userType === 'NORMAL' && record?.isRequestCreator
                      ? 'Chấp nhận yêu cầu'
                      : ' chưa có yêu cầu',
                  icon: <FaEdit className="mr-2" />,
                  onClick: () =>
                    record?.userType === 'NORMAL' && record?.isRequestCreator
                      ? handleConfirmCreator(record.id)
                      : toast.error('Chưa có yêu cầu'),
                  disabled: record?.userType === 'CONTENT_CREATOR' || !record?.isRequestCreator,
                },
                {
                  key: '3',
                  label: 'Xoá user',
                  icon: <FaEye className="mr-2" />,
                  danger: true,
                  onClick: () => {
                    Modal.confirm({
                      title: 'Bạn có chắc chắn muốn xoá người dùng này?',
                      content: 'Hành động này không thể hoàn tác',
                      okText: 'Xoá',
                      cancelText: 'Huỷ',
                      okButtonProps: { danger: true },
                      onOk: () => handleDelete(record.id),
                    });
                  },
                },
                {
                  key: '4',
                  label: record?.canReceiveDonation ? 'Tắt nhận donate ' : 'Bật nhận donate',
                  icon: record?.canReceiveDonation ? (
                    <IoCloseOutline className="mr-2" />
                  ) : (
                    <FaCheckCircle className="mr-2" />
                  ),
                  onClick: () => {
                    Modal.confirm({
                      title: record?.canReceiveDonation
                        ? 'Bạn có chắc chắn muốn tắt nhận donate?'
                        : 'Bạn có chắc chắn muốn bật nhận donate?',
                      content: 'Hành động này có thể ảnh hưởng đến người dùng',
                      okText: 'Xác nhận',
                      cancelText: 'Huỷ',
                      okButtonProps: { danger: true },
                      onOk: () => handleToggleChangeReciveDonate(record.id),
                    });
                  },
                },
              ],
            }}
          >
            <Button>
              <Space>
                Actions
                <FaChevronDown />
              </Space>
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];
  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">Quản lý người dùng</h1>
        <CreateUserModal />
      </div>
      <div className="bg-white p-4 rounded-lg ">
        <Table dataSource={data?.data} columns={columns} />
      </div>
    </>
  );
};

export default ManagementUser;
