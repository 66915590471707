import { Smile, Send } from 'lucide-react';
import React, { useCallback, useEffect, useState } from 'react';
import { postService } from '../../../services/post.service';
import { IReplyCommentParams } from '../../../models/post.model';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';

interface CommentReplyInputComponentProps {
  commentId: string;
  getReplyCommentWhenReply: (commentId: string) => void;
  setReplyCommentId: (commentId: string) => void;
  isShowReply: boolean;
  setIsShowReply: (value: boolean) => void;
}

const CommentReplyInputComponent = ({
  commentId,
  getReplyCommentWhenReply,
  setReplyCommentId,
  isShowReply,
  setIsShowReply,
}: CommentReplyInputComponentProps) => {
  const [replyCommentValue, setReplyCommentValue] = useState<string>('');
  const [showReplyEmojiPicker, setShowReplyEmojiPicker] = useState<boolean>(false);

  const handleSendRelyComment = async () => {
    //
    if (!replyCommentValue) {
      return;
    }

    setReplyCommentId(commentId);

    try {
      const payload: IReplyCommentParams = {
        content: replyCommentValue,
        replyToCommentId: commentId,
      };

      await postService.replyComment(payload);

      setIsShowReply(true);
      setReplyCommentValue('');

      if (isShowReply) {
        await getReplyCommentWhenReply(commentId);
      }

      // await refreshCommentClient();
    } catch (error) {
      //
    }
  };

  const onReplyEmojiSelect = useCallback((emoji: any) => {
    setReplyCommentValue((prev) => prev + emoji.native);
    setShowReplyEmojiPicker(false);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest('.emoji-picker-container')) {
        setShowReplyEmojiPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="mt-4 flex items-center gap-2">
      <div className="flex-1 relative">
        <input
          type="text"
          placeholder="Viết phản hồi..."
          className="w-full bg-gray-100 dark:bg-zinc-800 rounded-full px-4 py-2 text-sm outline-none pr-10"
          value={replyCommentValue}
          onChange={(e) => setReplyCommentValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSendRelyComment();
            }
          }}
        />
        <button
          onClick={() => setShowReplyEmojiPicker(!showReplyEmojiPicker)}
          className="absolute right-3 top-1/2 -translate-y-1/2"
        >
          <Smile size={20} className="text-slate-500" />
        </button>
        {showReplyEmojiPicker && (
          <div className="absolute bottom-full right-0 mb-2 z-10 emoji-picker-container">
            <Picker data={data} onEmojiSelect={onReplyEmojiSelect} />
          </div>
        )}
      </div>
      <button
        onClick={() => handleSendRelyComment()}
        className="text-blue-500 hover:text-blue-600 disabled:opacity-50"
        disabled={!replyCommentValue}
      >
        <Send size={16} />
      </button>
    </div>
  );
};

export default CommentReplyInputComponent;
