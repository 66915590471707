import { API } from '../Api';

export const fetchPostsApi = (page = 0, status = 'Uncheck') => {
  const param: { pageNumber: number; status?: string; pageSize: number } = { pageNumber: page, status, pageSize: 1000 };
  if (status === 'Uncheck') {
    delete param.status;
  }
  return new Promise<any>((resolve, reject) => {
    API.post('/v1/post-service/posts/get-uncheck-posts', param)
      .then((res) => {
        resolve(res);
      })
      .catch(reject);
  });
};

export const handleAdminPostApi = (postId: string, status: string) => {
  return new Promise<any>((resolve, reject) => {
    API.put('/v1/post-service/posts/approve', { postId, status })
      .then((res) => {
        resolve(res);
      })
      .catch(reject);
  });
};

export const fetchReportPostsApi = () => {
  return new Promise<any>((resolve, reject) => {
    API.get('/v1/user-service/admin/profile/get-all-user-report-post')
      .then((res) => {
        resolve(res);
      })
      .catch(reject);
  });
};

export const fetchReportPostByStateApi = (state: StateReport) => {
  return new Promise<any>((resolve, reject) => {
    API.get(`/v1/user-service/admin/profile/get-report-post-by-state?state=${state}`)
      .then((res) => {
        resolve(res);
      })
      .catch(reject);
  });
};

export const approveReportPostApi = (postId: string) => {
  return new Promise<any>((resolve, reject) => {
    API.put(`/v1/user-service/admin/profile/approve-user-report-post/${postId}`)
      .then((res) => {
        resolve(res);
      })
      .catch(reject);
  });
};
export const rejectReportPostApi = (postId: string) => {
  return new Promise<any>((resolve, reject) => {
    API.put(`/v1/user-service/admin/profile/reject-user-report-post/${postId}`)
      .then((res) => {
        resolve(res);
      })
      .catch(reject);
  });
};

export const getDashboardAdmin = () => {
  return new Promise<any>((resolve, reject) => {
    API.get(`/v1/user-service/admin/profile/dashboard/`)
      .then((res) => {
        resolve(res);
      })
      .catch(reject);
  });
};

export enum StateReport {
  Pending = 0,
  Accepted = 1,
  Rejected = 2,
}
