import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { usePostManagementHook } from '../../../../hooks/admin/post/use-post';
import { Post } from '../../../../components';
import Slider, { Settings } from 'react-slick';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { fetchPostsApi, handleAdminPostApi } from '../../../../api/admin/post.api';
import toast from 'react-hot-toast';

const CustomPrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} flex items-center justify-center bg-black text-white rounded-full w-10 h-10 hover:bg-gray-700 transition-all`}
      onClick={onClick}
    >
      <FaArrowLeft className="text-lg" />
    </div>
  );
};

const CustomNextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} flex items-center justify-center bg-black text-white rounded-full w-10 h-10 hover:bg-gray-700 transition-all`}
      onClick={onClick}
    >
      <FaArrowRight className="text-lg" />
    </div>
  );
};
const ManagementPost = (props) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [isRefetch, setIsRefetch] = useState(false);
  const [status, setStatus] = useState('Uncheck');
  const { data } = usePostManagementHook({ pageNumber, isRefetch, status });
  const [lengthPostApprove, setLengthPostApprove] = useState(0);
  const [lengthPostNotApprove, setLengthPostNotApprove] = useState(0);
  const [lengthPostPublic, setLengthPostPublic] = useState(0);
  const [lengthPostUnCheck, setLengthPostUnCheck] = useState(0);
  const getAllPostApprove = async () => {
    try {
      const { data } = await fetchPostsApi(1, 'Approved');
      setLengthPostApprove(data.data.length);
    } catch {
      //
    } finally {
      //
    }
  };
  const getAllPostNotApprove = async () => {
    try {
      const { data } = await fetchPostsApi(1, 'NotApproved');
      setLengthPostNotApprove(data.data.length);
    } catch {
      //
    } finally {
      //
    }
  };
  const getAllPostPublic = async () => {
    try {
      const { data } = await fetchPostsApi(1, 'Public');
      setLengthPostPublic(data.data.length);
    } catch {
      //
    } finally {
      //
    }
  };
  const getAllPostUnCheck = async () => {
    try {
      const { data } = await fetchPostsApi(1, 'Uncheck');
      setLengthPostUnCheck(data.data.length);
    } catch {
      //
    } finally {
      //
    }
  };
  const handlePost = async (postId, status) => {
    try {
      await handleAdminPostApi(postId, status);
      setIsRefetch(!isRefetch);
      toast.success('Thành công');
    } catch {
      //
    } finally {
      //
    }
  };
  useEffect(() => {
    getAllPostApprove();
    getAllPostNotApprove();
    getAllPostPublic();
    getAllPostUnCheck();
  }, []);

  return (
    <div className=" rounded-lg">
      <div className=" p-4 ">
        <div className=" text-2xl font-bold">Quản lý bài viết</div>
      </div>
      <div className="flex justify-between gap-2 p-4 cursor-pointer">
        <div
          className={`w-1/3 p-4 ${
            status === 'Uncheck' ? 'bg-yellow-500 text-white' : 'bg-gray-200  text-black'
          }  shadow-md rounded-lg`}
          onClick={() => setStatus('Uncheck')}
        >
          <div className="text-lg font-bold mb-2 "> {lengthPostUnCheck} bài viết</div>
          <p className="">Chưa duyệt</p>
        </div>
        <div
          className={`w-1/3 p-4 ${
            status === 'Approved' ? 'bg-green-500 text-white' : 'bg-gray-200  text-black'
          }  shadow-md rounded-lg`}
          onClick={() => setStatus('Approved')}
        >
          <div className="text-lg font-bold mb-2 "> {lengthPostApprove} bài viết</div>
          <p className="">Đã duyệt</p>
        </div>
        <div
          className={`w-1/3 p-4 ${
            status === 'Public' ? 'bg-blue-500 text-white' : 'bg-gray-200 r text-black'
          }  shadow-md rounded-lg`}
          onClick={() => setStatus('Public')}
        >
          <div className="text-lg font-bold mb-2 "> {lengthPostPublic} bài viết</div>
          <p className="">Public</p>
        </div>
        <div
          className={`w-1/3 p-4 ${
            status === 'NotApproved' ? 'bg-red-500 text-white' : 'bg-gray-200 r text-black'
          }  shadow-md rounded-lg`}
          onClick={() => setStatus('NotApproved')}
        >
          <div className="text-lg font-bold mb-2 "> {lengthPostNotApprove} bài viết</div>
          <p className="">Không duyệt</p>
        </div>
      </div>
      <div className=" flex justify-center w-full">
        <div className="gap-2 ">
          {data?.map((post, index) => {
            return (
              <div key={index} className="p-4 mb-4 bg-slate-300 rounded-lg w-full">
                {status === 'Uncheck' && (
                  <div className="w-full flex justify-between py-2 gap-2">
                    <button
                      onClick={() => handlePost(post.id, 'Public')}
                      className="w-1/2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                      Public
                    </button>
                    <button
                      onClick={() => handlePost(post.id, 'Approved')}
                      className="w-1/2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                    >
                      Duyệt
                    </button>
                    <button
                      onClick={() => handlePost(post.id, 'NotApproved')}
                      className="w-1/2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                    >
                      Không Duyệt
                    </button>
                  </div>
                )}
                <Post isAdmin key={post.id} post={post} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ManagementPost;
