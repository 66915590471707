import React from 'react';
import Avatar from '../../../components/avatar/avatar.component';
import ButtonPrimary from '../../../components/button-refactor/button-primary';
import { datingService } from '../../../services/dating.service';
import { IConfirmMatchRequest } from '../../../models/dating.model';
import { toast } from 'react-hot-toast';
import ButtonSecondary from '../../../components/button-refactor/button-secondary';

interface IMatchRequestsModel {
  requestId: string;
  senderProfileId: string;
  createdAt: string;
  nickname: string;
  avatar: string;
  age: number;
}

const Requests = ({ matchRequests, refetch }: { matchRequests: IMatchRequestsModel[]; refetch: () => void }) => {
  const onConfirmMatchRequest = async (value: any, isConfirm: boolean) => {
    try {
      const payload: IConfirmMatchRequest = {
        requestId: value.requestId,
        isConfirm,
      };
      await datingService.confirmMatchRequest(payload);
      toast.success(isConfirm ? 'Chấp nhận kết nối !' : 'Từ chối kết nối !');
      refetch();
    } catch (error) {
      toast.error('Có lỗi xảy ra !');
    }
  };

  return (
    <div className="flex flex-col gap-4 text-sm font-medium  dark:bg-dark2 p-4 h-full">
      <section className="">
        <div>
          <h3>Danh sách đang chờ</h3>
          <div className="grid grid-cols-2 gap-4 mt-4">
            {matchRequests.map((item) => (
              <div className="box dark:bg-dark2 py-2 px-2" key={item.requestId}>
                <div className="flex items-center">
                  <div className="mr-2">
                    <div className="border-4 border-white rounded-full">
                      <Avatar avatar={item.avatar || ''} label={item.nickname || ''} size="md" />
                    </div>
                  </div>
                  <div>
                    <div className="font-bold text-black dark:text-white">{item.nickname}</div>
                    <div className="text-gray-500 dark:text-gray-400">{item.age} tuổi</div>
                  </div>
                </div>
                <div className="flex justify-end gap-2 mt-4">
                  <ButtonSecondary className="whitespace-nowrap" onClick={() => onConfirmMatchRequest(item, false)}>
                    Từ chối
                  </ButtonSecondary>
                  <ButtonPrimary className="whitespace-nowrap" onClick={() => onConfirmMatchRequest(item, true)}>
                    Chấp nhận
                  </ButtonPrimary>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Requests;
