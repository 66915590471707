import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Alert from '../../components/alert/alert';
import { Helmet } from 'react-helmet-async';
import { routePaths } from '../../routes/routes-path';
import ButtonPrimary from '../../components/button-refactor/button-primary';
import Loading from '../../components/loading/loading.component';
import { useForm } from 'react-hook-form';
import { changePasswordApi, forgotPasswordApi } from '../../api';
import toast from 'react-hot-toast';

export const ForgotPasswordPage: React.FC = () => {
  const navigate = useNavigate();
  const [isSuccess, setIsSuccess] = React.useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string }>();

  const onSubmit = (data: { email: string }) => {
    forgotPasswordApi(data.email)
      .then(() => {
        toast.success('Gửi yêu cầu thành công');
        setIsSuccess(true);
      })
      .catch((error) => {
        toast.error('Có lỗi xảy ra, vui lòng thử lại.');
        console.error('Error:', error);
      });
  };

  return (
    <>
      <Helmet>
        <title>Quên mật khẩu - Đặt lại mật khẩu tài khoản</title>
        <meta name="description" content="Quên mật khẩu? Đặt lại mật khẩu tài khoản của bạn nhanh chóng và dễ dàng." />
      </Helmet>

      <Alert />

      <div className="flex justify-center items-center min-h-screen">
        <div className="w-full px-5 lg:px-0 mx-auto py-8">
          <div className="rounded-md bg-slate-50 dark:bg-zinc-800 max-w-md mx-auto p-6 shadow-lg">
            <h2 className="text-3xl font-bold mb-8 text-center">Quên mật khẩu</h2>

            {isSuccess ? (
              <div className="text-center text-green-600">Vui lòng kiểm tra email của bạn để đặt lại mật khẩu.</div>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-4">
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    aria-label="Email"
                    {...register('email', {
                      required: 'Email là bắt buộc',
                      pattern: {
                        value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                        message: 'Email không hợp lệ',
                      },
                    })}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  {errors.email?.message && (
                    <span className="text-red-500 text-sm">{String(errors.email.message)}</span>
                  )}
                </div>

                <ButtonPrimary type="submit">Gửi yêu cầu</ButtonPrimary>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
