import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { IUserProfile } from '../../models';
import dayjs from 'dayjs';
import { FieldErrors, useForm, UseFormReset, UseFormSetValue } from 'react-hook-form';
import * as yup from 'yup';
import ButtonSecondary from '../button-refactor/button-secondary';
import ButtonPrimary from '../button-refactor/button-primary';
import InputComponent from '../input/input.component';
import _ from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import ErrorValidateComponent from '../error-validate/error-validate.component';
import { CirclePlus, Trash } from 'lucide-react';
import { userService } from '../../services/user.service';
import toast from 'react-hot-toast';

interface Company {
  workPlaceName: string;
  from: string;
  to: string;
  position?: string;
}

interface School {
  workPlaceName: string;
  from: string;
  to: string;
  position?: string;
}

interface EditProfileOtherProps {
  profile: IUserProfile;
  onUpdateSuccess: () => void;
  onClose: () => void;
}

const schema = yup.object().shape({
  workPlaceName: yup.string().required('Vui lòng nhập tên trường học'),
  from: yup.string().required('Vui lòng nhập thời gian bắt đầu'),
  to: yup.string().required('Vui lòng nhập thời gian kết thúc'),
});

const EditProfileOther = ({ onClose, profile, onUpdateSuccess }: EditProfileOtherProps) => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [schools, setSchools] = useState<School[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmitForm = async () => {
    try {
      setLoading(true);
      const payload = {
        ...profile,
        schools,
        companies,
        categoryFavorites: profile.categoryFavorites.map((item: any) => item.categoryId),
      };
      await userService.updateProfile(payload as IUserProfile);
      onUpdateSuccess();
    } catch (error) {
      toast.error('Không thể cập nhật tài khoản của bạn');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (profile) {
      setSchools(profile.schools);
      setCompanies(profile.companies);
    }
  }, [profile]);

  return (
    <div>
      <div className="flex flex-col gap-4">
        <SchoolComponent schools={schools} setSchools={setSchools} />
        <CompanyComponent companies={companies} setCompanies={setCompanies} />
      </div>
      <div className="flex justify-end items-center mt-6">
        <ButtonSecondary className="mr-4" onClick={onClose}>
          Hủy bỏ
        </ButtonSecondary>
        <ButtonPrimary onClick={handleSubmitForm}>Cập nhật</ButtonPrimary>
      </div>
    </div>
  );
};

const SchoolComponent = ({
  schools,
  setSchools,
}: {
  schools: School[];
  setSchools: Dispatch<SetStateAction<School[]>>;
}) => {
  const [isEditSchool, setIsEditSchool] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<School>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (values: School) => {
    setSchools((prev) => [...prev, values]);
    setIsEditSchool(false);
  };

  const handleRemoveSchool = (index: number) => {
    setSchools((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <form className="flex flex-col gap-2" onSubmit={handleSubmit(onSubmit)}>
      <button type="button" onClick={() => setIsEditSchool(true)}>
        <div className="whitespace-nowrap text-sm text-slate-800 dark:text-slate-50 font-bold flex items-center gap-2 hover:underline">
          <CirclePlus size={16} />
          Trường học:
        </div>
      </button>
      {isEditSchool ? (
        <div className="flex flex-col gap-2 w-full">
          <Form
            formName="schools"
            errors={errors}
            reset={reset}
            register={register}
            setValue={setValue}
            placeholder="Tên trường"
          />
          <RenderAction onClick={() => setIsEditSchool(false)} />
        </div>
      ) : (
        <RenderList list={schools} type="school" handleRemove={handleRemoveSchool} />
      )}
    </form>
  );
};

const CompanyComponent = ({
  companies,
  setCompanies,
}: {
  companies: Company[];
  setCompanies: Dispatch<SetStateAction<Company[]>>;
}) => {
  const [isEditCompany, setIsEditCompany] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<Company>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (values: Company) => {
    setCompanies((prev) => [...prev, values]);
    setIsEditCompany(false);
  };

  const handleRemoveCompany = (index: number) => {
    setCompanies((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <form className="flex flex-col gap-2" onSubmit={handleSubmit(onSubmit)}>
      <button type="button" onClick={() => setIsEditCompany(true)}>
        <div className="whitespace-nowrap text-sm text-slate-800 dark:text-slate-50 font-bold flex items-center gap-2 hover:underline">
          <CirclePlus size={16} />
          Công ty:
        </div>
      </button>
      {isEditCompany ? (
        <div className="flex flex-col gap-2 w-full">
          <Form
            formName="companies"
            errors={errors}
            reset={reset}
            register={register}
            setValue={setValue}
            placeholder="Tên công ty"
          />
          <RenderAction onClick={() => setIsEditCompany(false)} />
        </div>
      ) : (
        <RenderList list={companies} type="company" handleRemove={handleRemoveCompany} />
      )}
    </form>
  );
};

const Form = ({
  formName,
  register,
  placeholder,
  reset,
  errors,
  setValue,
}: {
  formName: string;
  register: any;
  reset: UseFormReset<School | Company>;
  placeholder: string;
  errors: FieldErrors<School | Company>;
  setValue: UseFormSetValue<School | Company>;
}) => {
  useEffect(() => {
    reset();
  }, [reset]);

  const handleDateChange = (event: any, key: string) => {
    const { value } = event.target;
    const date = new Date(value);
    if (date.getFullYear() > 9999) {
      const currentYear = dayjs().year();
      const currentDate = dayjs(date).set('year', currentYear).format('YYYY-MM-DD');
      setValue(key as 'from' | 'to', currentDate);
    }
  };

  return (
    <div className="w-full flex flex-col gap-2">
      <div>
        <InputComponent
          placeholder={placeholder}
          id="workPlaceName"
          name="workPlaceName"
          register={register('workPlaceName')}
        />
        <ErrorValidateComponent visible={!!errors.workPlaceName}>
          {errors.workPlaceName?.message}
        </ErrorValidateComponent>
      </div>
      <div className="flex gap-2 w-full">
        <div className="form-group w-full">
          <label
            htmlFor="from"
            className="flex items-center text-xs font-medium text-slate-800 dark:text-slate-50 mb-2"
          >
            Thời gian bắt đầu
          </label>
          <InputComponent
            placeholder="Thời gian bắt đầu"
            id="from"
            register={register('from')}
            type="date"
            onChange={(e) => handleDateChange(e, 'from')}
          />
          <ErrorValidateComponent visible={!!errors.from}>{errors.from?.message}</ErrorValidateComponent>
        </div>
        <div className="form-group w-full">
          <label
            htmlFor="to"
            className="flex items-center text-xs font-medium text-slate-800 dark:text-slate-50  mb-2 "
          >
            Thời gian kết thúc
          </label>
          <InputComponent
            placeholder="Thời gian kết thúc"
            id="to"
            register={register('to')}
            type="date"
            onChange={(e) => handleDateChange(e, 'to')}
          />
          <ErrorValidateComponent visible={!!errors.to}>{errors.to?.message}</ErrorValidateComponent>
        </div>
      </div>
      <div className="flex flex-col gap-1">
        <InputComponent
          placeholder={`${formName === 'schools' ? 'Hiện tại' : 'Chức vụ/ Vị trí'}`}
          id="position"
          register={register('position')}
        />
      </div>
    </div>
  );
};

const RenderList = ({
  list,
  type,
  handleRemove,
}: {
  list: School[] | Company[];
  type: 'school' | 'company';
  handleRemove: (index: number) => void;
}) => {
  const renderDate = (date: string) => {
    if (!date || !dayjs(date).isValid()) return 'Chưa có';
    return dayjs(date).format('DD/MM/YYYY');
  };

  if (_.isEmpty(list)) return <div className="text-slate-500">Trống</div>;

  return (
    <div className="flex flex-col gap-2">
      {_.map(list, (item, index) => (
        <div key={index} className="flex items-center gap-1">
          <span className="font-bold mr-2">{item.workPlaceName}</span>
          {item.position && <span className="text-xs">{item.position}</span>}
          <span className="text-xs text-slate-500">
            ({renderDate(item.from)} - {renderDate(item.to)})
          </span>
          <button className="cursor-pointer text-red-600" onClick={() => handleRemove(index)}>
            <Trash size={14} />
          </button>
        </div>
      ))}
    </div>
  );
};

const RenderAction = ({ onClick }: { onClick: () => void }) => {
  return (
    <div className="flex gap-4 justify-end">
      <button className="text-red-600 font-medium" type="button" onClick={onClick}>
        Hủy bỏ
      </button>
      <button type="submit" className="text-blue-600 font-medium">
        Thêm
      </button>
    </div>
  );
};

export default EditProfileOther;
