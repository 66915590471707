import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { usePostManagementHook } from '../../../../hooks/admin/post/use-post';
import { Post } from '../../../../components';
import Slider, { Settings } from 'react-slick';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import {
  approveReportPostApi,
  fetchPostsApi,
  fetchReportPostByStateApi,
  handleAdminPostApi,
  rejectReportPostApi,
  StateReport,
} from '../../../../api/admin/post.api';
import { useReportPostManagementHook } from '../../../../hooks/admin/report-post/use-report-post';
import { fetchUserProfileByIdApi, getDetailPost } from '../../../../api';
import { postService } from '../../../../services/post.service';
import dayjs from 'dayjs';

const CustomPrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} flex items-center justify-center bg-black text-white rounded-full w-10 h-10 hover:bg-gray-700 transition-all`}
      onClick={onClick}
    >
      <FaArrowLeft className="text-lg" />
    </div>
  );
};

const CustomNextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} flex items-center justify-center bg-black text-white rounded-full w-10 h-10 hover:bg-gray-700 transition-all`}
      onClick={onClick}
    >
      <FaArrowRight className="text-lg" />
    </div>
  );
};
const ManagementReportPost = (props) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [isRefetch, setIsRefetch] = useState(false);
  const [status, setStatus] = useState(0);
  const { data } = useReportPostManagementHook({ isRefetch, status });
  const [lengthReportPending, setLengthReportPending] = useState(0);
  const [lengthReportAccepted, setLengthReportAccepted] = useState(0);
  const [lengthReportRejected, setLengthReportRejected] = useState(0);

  const getAllPostReportPending = async () => {
    try {
      const { data } = await fetchReportPostByStateApi(0);
      setLengthReportPending(data.length);
    } catch {
      //
    } finally {
      //
    }
  };
  const getAllReportAccepted = async () => {
    try {
      const { data } = await fetchReportPostByStateApi(1);
      setLengthReportAccepted(data.length);
    } catch {
      //
    } finally {
      //
    }
  };
  const getAllReportRejected = async () => {
    try {
      const { data } = await fetchReportPostByStateApi(2);
      setLengthReportRejected(data.length);
    } catch {
      //
    } finally {
      //
    }
  };
  useEffect(() => {
    getAllReportRejected();
    getAllReportAccepted();
    getAllPostReportPending();
  }, []);

  return (
    <div className=" rounded-lg">
      <div className=" p-4 ">
        <div className=" text-2xl font-bold">Quản lý bài viết bị report</div>
      </div>
      <div className="flex justify-between gap-2 p-4 cursor-pointer ">
        <div
          className={`w-1/3 p-4 ${
            status === StateReport.Pending ? 'bg-yellow-500 text-white' : 'bg-gray-200  text-black'
          }  shadow-md rounded-lg`}
          onClick={() => setStatus(StateReport.Pending)}
        >
          <div className="text-lg font-bold mb-2 "> {lengthReportPending} report</div>
          <p className="">Đang chờ xử lý</p>
        </div>
        <div
          className={`w-1/3 p-4 ${
            status === StateReport.Accepted ? 'bg-green-500 text-white' : 'bg-gray-200  text-black'
          }  shadow-md rounded-lg`}
          onClick={() => setStatus(StateReport.Accepted)}
        >
          <div className="text-lg font-bold mb-2 "> {lengthReportAccepted} report</div>
          <p className="">Được chấp thuận</p>
        </div>

        <div
          className={`w-1/3 p-4 ${
            status === StateReport.Rejected ? 'bg-red-500 text-white' : 'bg-gray-200 r text-black'
          }  shadow-md rounded-lg`}
          onClick={() => setStatus(StateReport.Rejected)}
        >
          <div className="text-lg font-bold mb-2 "> {lengthReportRejected} report</div>
          <p className="">Không được chấp thuận</p>
        </div>
      </div>
      <div className=" flex justify-center w-full">
        <div className="gap-2 w-full">
          {data?.map((post, index) => {
            return <MyComponent key={index} post={post} status={status} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default ManagementReportPost;

const MyComponent = ({ post, status }: { post: any; status: any }) => {
  const [item, setItem] = useState<any>();
  const [reportByUser, setReportByUser] = useState<any>();
  const handlePostApprove = async (postId) => {
    try {
      await approveReportPostApi(postId);
    } catch {
      //
    } finally {
      //
    }
  };
  const handleRejectReportPost = async (postId) => {
    try {
      await rejectReportPostApi(postId);
    } catch {
      //
    } finally {
      //
    }
  };
  useEffect(() => {
    const handleGetDetailPost = async (postId) => {
      const res = await getDetailPost(postId);
      setItem(res);
    };

    const getReportByUserPost = async (postId) => {
      const res = await fetchUserProfileByIdApi(postId);
      setReportByUser(res.data);
    };

    handleGetDetailPost(post.postId);
    getReportByUserPost(post.reportByUserId);
  }, [post.postId]);

  if (!item) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-4 mb-4 bg-slate-300 rounded-lg ">
      <div className="flex w-full">
        <div className="w-1/2">
          <Post isAdmin key={item?.id} post={item} />
        </div>
        <div className="w-1/2">
          <div className="flex justify-between">
            {' '}
            <h3 className="font-bold w-[20%]">Người report :</h3>
            <p className="text-black w-[80%]">{reportByUser?.fullname ? reportByUser?.fullname : 'Khong Xac Dinh'}</p>
          </div>
          <div className="flex justify-between">
            {' '}
            <h3 className="font-bold w-[20%]">Thời gian report :</h3>
            <p className="text-black text-left w-[80%]">{dayjs(post?.updatedAt).format('DD-MM-YYYY')}</p>
          </div>{' '}
          <div className="flex justify-between">
            {' '}
            <h3 className="font-bold w-[20%]">Lý do report :</h3>
            <p className="text-black w-[80%]">{post?.reportPostValue}</p>
          </div>{' '}
          <div className="flex justify-between">
            {' '}
            <h3 className="font-bold w-[20%]">Nội dung report :</h3>
            <p className="text-black w-[80%]">{post?.content}</p>
          </div>
        </div>
      </div>
      {status === StateReport.Pending && (
        <div className="w-full flex justify-between py-2 gap-2">
          <button
            onClick={() => handlePostApprove(post.id)}
            className="w-1/2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
          >
            Chấp thuận
          </button>
          <button
            onClick={() => handleRejectReportPost(post.id)}
            className="w-1/2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          >
            Không chấp thuận
          </button>
        </div>
      )}
    </div>
  );
};
