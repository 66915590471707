import React from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import Alert from '../../components/alert/alert';
import { Helmet } from 'react-helmet-async';
import { routePaths } from '../../routes/routes-path';
import ButtonPrimary from '../../components/button-refactor/button-primary';
import Loading from '../../components/loading/loading.component';
import { useForm } from 'react-hook-form';
import { changePasswordApi } from '../../api';
import toast from 'react-hot-toast';

export const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const token = searchParams.get('token');
  const onSuccess = () => {};

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    // Handle form submission
    console.log(data);
    email &&
      token &&
      changePasswordApi(email, token, data.password).then(() => {
        toast.success('Thay đổi mật khẩu thành công');
        navigate(routePaths.login);
      });
  };
  React.useEffect(() => {
    if (!token) {
      navigate(routePaths.login);
    }
  }, [token, navigate]);
  return (
    <>
      {' '}
      <Helmet>
        <title>Thay đổi mật khẩu</title>
        <meta name="description" content="Xác nhận email" />
      </Helmet>
      <Alert />
      <div className="flex justify-center items-center min-h-screen">
        <div className="w-full px-5 lg:px-0 mx-auto py-8">
          <div className="rounded-md bg-slate-50 dark:bg-zinc-800 max-w-md mx-auto p-6 shadow-lg">
            <h2 className="text-3xl font-bold mb-8 text-center">Thay đổi mật khẩu</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-4">
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Mật khẩu mới
                </label>
                <input
                  type="password"
                  id="password"
                  {...register('password', { required: true })}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                {errors.password && <span className="text-red-500 text-sm">This field is required</span>}
              </div>

              <ButtonPrimary type="submit">Thay đổi mật khẩu</ButtonPrimary>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
