import React, { useState } from 'react';
import { IPostModel } from '../../../models';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import classNames from 'classnames';
import PreviewPostDialog from '../../preview-post-dialog/preview-post-dialog.componet';

const PostTypeImage = ({ post, isShare }: { post: IPostModel; isShare?: boolean }) => {
  const [indexViewImage, setIndexViewImage] = useState(0);
  const [visiblePreview, setVisiblePreview] = useState(false);

  if (post.postImageResponses && post.postImageResponses.length === 0) return null;

  return (
    <div className={`relative w-full h-full ${isShare ? '' : 'sm:px-4'}`}>
      {visiblePreview ? (
        <PreviewPostDialog
          visible={visiblePreview}
          onclose={() => setVisiblePreview(false)}
          post={post as IPostModel}
          initialSlide={indexViewImage}
        />
      ) : null}
      <RenderImage
        postImageResponses={post?.postImageResponses || []}
        setIndexViewImage={setIndexViewImage}
        setVisiblePreview={setVisiblePreview}
        indexViewImage={indexViewImage}
      />
    </div>
  );
};

const RenderImage = ({
  postImageResponses,
  setIndexViewImage,
  setVisiblePreview,
  indexViewImage,
}: {
  postImageResponses: any[];
  setIndexViewImage: (index: number) => void;
  setVisiblePreview: (visible: boolean) => void;
  indexViewImage: number;
}) => {
  if (postImageResponses && postImageResponses.length === 1) {
    return (
      <LazyLoadImage
        onClick={() => {
          setIndexViewImage(0);
          setVisiblePreview(true);
        }}
        className="rounded-md object-cover w-full max-h-[500px]"
        src={postImageResponses[0].url}
        alt={postImageResponses[0].url}
      />
    );
  }
  return (
    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3, 1024: 2 }}>
      <Masonry gutter="12px">
        {postImageResponses &&
          postImageResponses?.map((image, index) => {
            if (index > 3) return;
            if (index === 3)
              return (
                <div key={image.imageId} className="relative block rounded-md">
                  <div className="absolute z-[1] top-0 left-0 rounded-md w-full h-full bg-black/50 text-white font-medium backdrop-blur p-4">
                    <div
                      className="h-full flex justify-center items-center cursor-pointer text-sm"
                      onClick={() => {
                        setIndexViewImage(index);
                        setVisiblePreview(true);
                      }}
                    >
                      Xem thêm
                    </div>
                  </div>

                  <LazyLoadImage
                    className={classNames(`rounded-md object-cover w-full h-full`, {
                      'blur-md': image.isViolence && index !== indexViewImage,
                    })}
                    src={image.url}
                    alt={image.url}
                    onClick={() => {
                      if (postImageResponses.length < 3) {
                        return;
                      }
                      setIndexViewImage(index);
                      setVisiblePreview(true);
                    }}
                  />
                </div>
              );

            return (
              <button key={index} className="relative block rounded-md">
                <LazyLoadImage
                  className={classNames(`rounded-md object-cover w-full h-full`, {
                    'blur-md': image.isViolence && index !== indexViewImage,
                  })}
                  src={image.url}
                  alt={image.url}
                  onClick={() => {
                    if (postImageResponses.length < 3) {
                      return;
                    }
                    setIndexViewImage(index);
                    setVisiblePreview(true);
                  }}
                />
              </button>
            );
          })}
      </Masonry>
    </ResponsiveMasonry>
  );
};

export default PostTypeImage;
