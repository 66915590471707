import { API } from '../Api';

export function changePasswordApi(email: string, token: string, password: string) {
  return new Promise<void>((resolve, reject) => {
    API.post('/v1/identity-service/accounts/password', { email, token, password })
      .then(() => resolve())
      .catch(reject);
  });
}

export function forgotPasswordApi(email: string) {
  return new Promise<void>((resolve, reject) => {
    API.post('/v1/identity-service/accounts/password-request', { email })
      .then(() => resolve())
      .catch(reject);
  });
}
