import React from 'react';
import { NavLink } from 'react-router-dom';
import { IPostModel } from '../../models';
import { handleFormatHastag } from '../../utils/formatHastag';

const PostHastags = ({ post }: { post?: IPostModel }) => {
  if (!post?.hashtags || post?.hashtags.length === 0) return null;
  return (
    <ul className="flex text-branding text-sm py-4">
      {post?.hashtags?.split(' ').map((ht, index) => {
        return (
          <li className="mr-1 text-violet-800 dark:text-violet-500" key={index}>
            <NavLink
              to={`/hashtag/${handleFormatHastag(ht)}`}
              className={({ isActive, isPending }) => (isActive ? 'pointer-events-none' : '')}
            >
              #{handleFormatHastag(ht)}
            </NavLink>
          </li>
        );
      })}
    </ul>
  );
};

export default PostHastags;
