import React from 'react';
import { IPostModel } from '../../../models';
import { useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player';

const PostTypeVideo = ({ post }: { post: IPostModel }) => {
  const navigate = useNavigate();
  if (post.postVideoResponses && post.postVideoResponses.length === 0) return null;
  return (
    <div className="cursor-pointer" onDoubleClick={() => navigate(`videos/${post.id}`)}>
      <div
        onClick={(e: any) => {
          e.stopPropagation();
        }}
      >
        <ReactPlayer
          config={{
            file: {
              attributes: {
                controlsList: 'nofullscreen',
              },
            },
          }}
          width="100%"
          height="100%"
          url={post?.postVideoResponses[0]?.url}
          loop
          controls
        />
      </div>
    </div>
  );
};

export default PostTypeVideo;
