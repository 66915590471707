import { useEffect, useState } from 'react';
import { fetchAllCategory } from '../../api/user-profile/user-profile.api';
import { IOption } from '../../select/select.component';
import { fetchPostCategoriesApi } from '../../api';
import { IPostCategory } from '../../models';
import _ from 'lodash';

export const useCategories = ({ categoryId, type }: { categoryId?: string; type: 'post' | 'all' }) => {
  const [categories, setCategories] = useState<IOption[]>([]);
  const [postCategories, setPostCategories] = useState<IPostCategory[]>([]);

  const fetchCategoryOptions = async () => {
    try {
      // Replace this URL with the actual endpoint from which you're fetching category options
      const response = await fetchAllCategory();
      const data: any[] = response.map((item: any) => ({ value: item.id, label: item.name }));
      setCategories(data);
      // Optionally, update form default values here if necessary
    } catch (error) {
      console.error('Failed to fetch category options:', error);
    }
  };

  const fetchPostCategories = async () => {
    const response = await fetchPostCategoriesApi();
    setPostCategories(response as unknown as IPostCategory[]);
  };

  const getCategory = (id: string) => {
    return categories.find((category) => category.value === id);
  };

  const getPostCategory = (id: string) => {
    return _.find(postCategories, (category) => category.id === id);
  };

  useEffect(() => {
    if (type === 'all') {
      fetchCategoryOptions();
    }
    if (type === 'post') {
      fetchPostCategories();
    }
  }, []);

  return {
    categories,
    fetchCategoryOptions,
    getCategory,
    getPostCategory,
    postCategories,
  };
};
