import { API } from '../api';
import { IConfirmMatchRequest, IDatingProfileModel, ISendMatchRequest } from '../models/dating.model';
import { IDataResponse } from '../types/common';

const endPoint = {
  datingProfile: 'v1/user-service/datings/user-dating-profile',
  userDatingProfiles: 'v1/user-service/datings/user-dating-profiles/filtered',
  updateDatingProfile: 'v1/user-service/datings/create-or-update-dating-profile',
  sendMatchRequest: 'v1/user-service/datings/send-match-request',
  matchRequestsByProfileId: 'v1/user-service/datings/match-requests-by-profile-id',
  confirmMatchRequest: 'v1/user-service/datings/is-confirm-match-request',
};

export const datingService = {
  async getDatingProfile() {
    const { data } = await API.get<IDatingProfileModel>(endPoint.datingProfile);
    return data;
  },
  async getUserDatingProfiles(payload: any) {
    const { data } = await API.post<IDatingProfileModel[]>(endPoint.userDatingProfiles, payload);
    return data;
  },
  async updateDatingProfile(payload: IDatingProfileModel) {
    const { data } = await API.post<IDataResponse<IDatingProfileModel>>(endPoint.updateDatingProfile, payload);
    return data;
  },
  async sendMatchRequest(payload: ISendMatchRequest) {
    const { data } = await API.post<IDataResponse<any>>(endPoint.sendMatchRequest, payload);
    return data;
  },
  async getMatchRequestsByProfileId(profileId: string) {
    const { data } = await API.get<IDataResponse<any>>(endPoint.matchRequestsByProfileId, {
      params: { uuid: profileId },
    });
    return data;
  },
  async confirmMatchRequest(payload: IConfirmMatchRequest) {
    const { data } = await API.post<IDataResponse<any>>(endPoint.confirmMatchRequest, payload);
    return data;
  },
};
