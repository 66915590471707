import { API } from '..';
import { ReportPostModel } from '../../models/report-post-model';

export const reportPostApi = (params: ReportPostModel) => {
  return new Promise<boolean>((resolve, reject) => {
    API.post(`/report/user-service/create-report`, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
};
