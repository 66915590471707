import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import {
  HomePage,
  LoginPage,
  RegisterPage,
  ProfilePage,
  NotFoundPage,
  NotificationPage,
  CreatorRequest,
  VerifyAccountPage,
  ProfileUserPage,
  FriendPage,
} from '../pages';

import { routePaths } from './routes-path';
import DefaultLayout from '../components/layouts/default-layout';
import BlankLayout from '../components/layouts/blank-layout';
import PrivateRoute from '../components/route/private-route.component';
import { ListFriendPage } from '../pages/list-friend';
import { ChatPage } from '../pages/chat';
import { VideosPage } from '../pages/videos';
import { DatingPage } from '../pages/dating';
import VideosLayout from '../components/layouts/videos-layout';
import UserSetting from '../pages/user-setting/user-setting';
import PageDonateLivestream from '../pages/page-donate-livestream/page-donate-livestream.component';
import GroupPage from '../pages/group/group.page';
import GroupDetail from '../pages/group-detail';
import AdminLoginPage from '../pages/admin/login.page';
import AdminPage from '../pages/admin/admin.page';
import CreatorSuggestionPage from '../pages/creator-suggestion';
import HomeAdmin from '../pages/admin/page/HomeAdmin';
import AdminLayout from '../pages/admin/layout/DefaultLayout';
import { SavedPostsPage } from '../pages/saved-posts/saved-posts.page';
import ManagementUser from '../pages/admin/page/ManagementUser';
import ManagementRequestDonate from '../pages/admin/page/ManagementRequestDonate';
import HashtagPage from '../pages/hastag/hastag.page';
import { ResetPasswordPage } from '../pages/reset-password';
import LoginByCodePage from '../pages/admin/codeLoginCheck.page';
import ManagementPost from '../pages/admin/page/ManagementPost';
import ManagementReportPost from '../pages/admin/page/ManagementReportPost';
import PostUncheck from '../pages/post-uncheck';
import { CategoryPage } from '../pages/category';
import { ForgotPasswordPage } from '../pages/forgot-password';

export const RouterLinks = createBrowserRouter([
  {
    path: '/',
    children: [
      {
        element: <DefaultLayout />,
        children: [
          {
            element: <PrivateRoute />,
            children: [
              {
                index: true,
                element: <HomePage />,
              },
              {
                path: 'share/post/:id', // Matches "/share/..." with dynamic segments
                element: <HomePage />, // Replace with your SharePage component
              },
              {
                path: routePaths.notifications,
                Component: NotificationPage,
              },
              {
                path: routePaths.creatorsRequest,
                Component: CreatorRequest,
              },
              {
                path: routePaths.creatorsSuggestion,
                Component: CreatorSuggestionPage,
              },
              {
                path: routePaths.savePost,
                Component: SavedPostsPage,
              },
              {
                path: routePaths.profile,
                element: <ProfilePage />,
              },
              {
                path: routePaths.chat,
                element: <ChatPage />,
              },
              {
                path: routePaths.group,
                element: <GroupPage />,
              },
              {
                path: routePaths.groupDetail,
                element: <GroupDetail />,
              },
              {
                path: routePaths.category,
                element: <CategoryPage />,
              },
              {
                path: routePaths.hashtag,
                element: <HashtagPage />,
              },
              {
                path: '/post-uncheck',
                Component: PostUncheck,
              },
            ],
          },
        ],
      },
      {
        element: <BlankLayout />,
        children: [
          {
            element: <PrivateRoute />,
            children: [
              {
                path: routePaths.dating,
                Component: DatingPage,
              },
            ],
          },
        ],
      },
      {
        element: <VideosLayout />,
        children: [
          {
            element: <PrivateRoute />,
            children: [
              {
                path: routePaths.videos,
                Component: VideosPage,
              },
              {
                path: routePaths.videoDetail,
                element: <VideosPage />,
              },
            ],
          },
        ],
      },
      {
        element: <BlankLayout />,
        children: [
          {
            element: <PrivateRoute />,
            children: [
              {
                path: routePaths.profileUser,
                element: <ProfileUserPage />,
              },
              {
                path: routePaths.friend,
                element: <FriendPage />,
              },
              {
                path: routePaths.listFriend,
                element: <ListFriendPage />,
              },

              {
                path: routePaths.userSetting,
                Component: UserSetting,
              },
            ],
          },
        ],
      },
      {
        path: routePaths.notiDonate,
        element: <PageDonateLivestream />,
      },
    ],
  },

  {
    path: routePaths.register,
    Component: RegisterPage,
  },
  {
    path: routePaths.login,
    Component: LoginPage,
  },
  {
    path: routePaths.resetPassword,
    Component: ResetPasswordPage,
  },
  {
    path: '/reset-password',
    Component: ForgotPasswordPage,
  },
  {
    path: routePaths.verifyEmail,
    Component: VerifyAccountPage,
  },
  {
    path: routePaths.admin,
    element: <AdminLayout />,
    children: [
      {
        path: '',
        Component: HomeAdmin,
      },
      {
        path: 'user',
        Component: ManagementUser,
      },
      {
        path: 'request-donate',
        Component: ManagementRequestDonate,
      },
      {
        path: 'post',
        Component: ManagementPost,
      },
      {
        path: 'report-post',
        Component: ManagementReportPost,
      },
    ],
  },
  {
    path: routePaths.admin,
    children: [
      {
        path: 'login',
        Component: AdminLoginPage,
      },
      {
        path: 'two-factor/:email',
        Component: LoginByCodePage,
      },
    ],
  },
  {
    path: '*',
    Component: NotFoundPage,
  },
]);
