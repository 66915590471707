import React, { useEffect, useState } from 'react';
import { IUserProfile } from '../../models';
import InputComponent from '../input/input.component';
import * as yup from 'yup';
import Select, { IOption } from '../../select/select.component';
import ButtonSecondary from '../button-refactor/button-secondary';
import ButtonPrimary from '../button-refactor/button-primary';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { userService } from '../../services/user.service';
import toast from 'react-hot-toast';
import ErrorValidateComponent from '../error-validate/error-validate.component';
import ReactSelect, { MultiValue } from 'react-select';
import { fetchAllCategory } from '../../api';
import dayjs from 'dayjs';
import _ from 'lodash';

interface EditProfileGeneralProps {
  onClose: () => void;
  profile: IUserProfile;

  onUpdateSuccess: () => void;
}

const schema = yup.object().shape({
  // username: yup.string().required('Vui lòng nhập tên người dùng'),
  // email: yup.string().email('Email không hợp lệ').required('Vui lòng nhập email'),
  dob: yup.string().required('Ngày sinh không được bỏ trống'),
  sex: yup.string().required('Vui lòng nhập giới tính'),
  customSex: yup.string(),
  gender: yup.string(),
  // city: yup.string().required('Vui lòng nhập tên thành phố'),
  description: yup.string().required('Vui lòng nhập mô tả'),
  fullname: yup.string().required('Vui lòng nhập họ tên'),
  categoryFavorites: yup
    .array()
    .of(yup.string())
    .min(1, 'Vui lòng chọn sở thích')
    .required('Sở thích không được bỏ trống'),
});

interface IFormValues {
  // username: string;
  // email: string;
  dob: string;
  sex: string;
  customSex?: string;
  // city: string;
  description: string;
  fullname: string;
  categoryFavorites: any[];
  sexualOrientation?: number;
  gender?: string;
  marry?: number;
}

const options: IOption[] = [
  {
    label: 'Nam',
    value: 'male',
  },
  {
    label: 'Nữ',
    value: 'female',
  },
  {
    label: 'Tùy chọn',
    value: 'other',
  },
];

const optionsRelationship: IOption[] = [
  {
    label: 'Độc thân',
    value: 0,
  },
  {
    label: 'Đã kết hôn',
    value: 1,
  },
];

const optionsSexualOrientation: IOption[] = [
  {
    label: 'Nam',
    value: 0,
  },
  {
    label: 'Nữ',
    value: 1,
  },
  {
    label: 'Tùy chọn',
    value: 2,
  },
];

const EditProfileGeneral = ({ onClose, profile, onUpdateSuccess }: EditProfileGeneralProps) => {
  const { dob, city, description, sex, username, email, fullname, categoryFavorites, sexualOrientation, marry } =
    profile;
  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [categoryOptions, setCategoryOptions] = useState<IOption[]>([]);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
    setError,
    clearErrors,
  } = useForm<IFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      dob,
      // city,
      description,
      sex,
      // username,
      // email,
      fullname,
      categoryFavorites: categoryFavorites?.map((item) => item?.categoryId),
      sexualOrientation,
      gender: 'ss',
    },
  });

  const fetchCategoryOptions = async () => {
    try {
      // Replace this URL with the actual endpoint from which you're fetching category options
      const response = await fetchAllCategory();
      const data: any[] = response.map((item: any) => ({ value: item.id, label: item.name }));
      setCategoryOptions(data);
      // Optionally, update form default values here if necessary
    } catch (error) {
      console.error('Failed to fetch category options:', error);
    }
  };

  // Fetch category options on component mount
  useEffect(() => {
    fetchCategoryOptions();
  }, []);

  useEffect(() => {
    if (profile) {
      reset({
        ...profile,
        categoryFavorites: _.map(profile.categoryFavorites, (item) => item.categoryId),
        dob: dayjs(profile.dob).format('YYYY-MM-DD'),
      });
    }
  }, [profile, reset]);

  const onSubmit: SubmitHandler<IFormValues> = async (formValues) => {
    try {
      setLoading(true);
      const payload = {
        ...profile,
        ...formValues,
        email: profile.email,
        customSex: formValues.customSex || 'Chưa rõ',
        dob: dayjs(formValues.dob).format('YYYY-MM-DD'),
      };
      await userService.updateProfile(payload as IUserProfile);

      onUpdateSuccess();
      setVisible(false);
      toast.success('Cập nhật thông tin thành công');
    } catch (error) {
      toast.error('Không thể cập nhật tài khoản của bạn');
      setVisible(false);
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (event: any) => {
    const { value } = event.target;
    const date = new Date(value);
    if (date.getFullYear() > 9999) {
      const currentDob = dayjs(profile.dob).set('year', 1999).format('YYYY-MM-DD');
      setValue('dob', currentDob);
    }
  };

  return (
    <form className="space-y-4 py-5 font-bold" onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group ">
        <div className="flex">
          <label htmlFor="fullname" className="flex items-center w-[20%] text-sm text-slate-800 dark:text-slate-50 ">
            Họ Tên
          </label>
          <div className="w-full">
            <InputComponent placeholder="Tên tài khoản" id="fullname" register={register('fullname')} />
            <ErrorValidateComponent visible={!!errors.fullname}>{errors.fullname?.message}</ErrorValidateComponent>
          </div>
        </div>
      </div>
      <div className="form-group ">
        <div className="flex">
          <label htmlFor="gender" className="flex items-center w-[20%] text-sm text-slate-800 dark:text-slate-50 ">
            Giới tính
          </label>
          <div className="w-full flex flex-col gap-2">
            <div className="font-normal">
              <Select
                className="bg-[#f1f5f9]"
                options={options}
                onChange={(data: any) => {
                  setValue('sex', data.value);
                }}
                placeholder="Giới tính"
                value={watch('sex')}
              />
              <ErrorValidateComponent visible={!!errors.sex}>{errors.sex?.message}</ErrorValidateComponent>
            </div>
            {watch('sex') === 'other' && (
              <InputComponent
                placeholder="Nhập giới tính"
                register={register('customSex')}
                value={watch('customSex')?.replaceAll(/[0-9]/g, '')}
              />
            )}
          </div>
        </div>
      </div>
      <div className="form-group ">
        <div className="flex">
          <label htmlFor="dob" className="flex items-center w-[20%] text-sm text-slate-800 dark:text-slate-50 ">
            Ngày sinh
          </label>
          <div className="w-full">
            <InputComponent
              type="date"
              placeholder="Ngày sinh"
              id="dob"
              onChange={handleDateChange}
              register={register('dob')}
            />
            <ErrorValidateComponent visible={!!errors.dob}>{errors.dob?.message}</ErrorValidateComponent>
          </div>
        </div>
      </div>
      <div className="form-group ">
        <div className="flex">
          <label htmlFor="description" className="flex items-center w-[20%] text-sm text-slate-800 dark:text-slate-50 ">
            Mô tả
          </label>
          <div className="w-full">
            <InputComponent type="textarea" placeholder="Mô tả" id="description" register={register('description')} />
            <ErrorValidateComponent visible={!!errors.description}>
              {errors.description?.message}
            </ErrorValidateComponent>
          </div>
        </div>
      </div>
      <div className="form-group ">
        <div className="flex">
          <label
            htmlFor="categoryFavorites"
            className="flex items-center w-[20%] text-sm text-slate-800 dark:text-slate-50 "
          >
            Sở thích
          </label>
          <div className="w-full">
            {categoryOptions.length > 0 && (
              <ReactSelect
                isMulti
                options={categoryOptions}
                onChange={(selectedOptions: MultiValue<any>) => {
                  setValue(
                    'categoryFavorites',
                    selectedOptions.map((option) => option.value),
                  );
                }}
                placeholder="Sở thích"
                value={categoryOptions.filter((option) => {
                  if (watch('categoryFavorites')) {
                    return watch('categoryFavorites').includes(option.value);
                  }
                  return false;
                })}
              />
            )}
          </div>
          <ErrorValidateComponent visible={!!errors.categoryFavorites}>
            {errors.categoryFavorites?.message}
          </ErrorValidateComponent>
        </div>
      </div>
      <div className="form-group ">
        <div className="flex">
          <label htmlFor="gender" className="flex items-center w-[20%] text-sm text-slate-800 dark:text-slate-50 ">
            Bạn thích
          </label>
          <div className="w-full flex flex-col gap-2">
            <Select
              options={optionsSexualOrientation}
              onChange={(data: any) => {
                setValue('sexualOrientation', data.value);
              }}
              placeholder="Giới tính"
              value={watch('sexualOrientation')}
            />
            {watch('sexualOrientation') === 2 && (
              <InputComponent
                placeholder="Nhập giới tính"
                register={register('gender')}
                value={watch('gender')?.replaceAll(/[0-9]/g, '')}
              />
            )}
          </div>
        </div>
      </div>
      <div className="form-group ">
        <div className="flex">
          <label htmlFor="gender" className="flex items-center w-[20%] text-sm text-slate-800 dark:text-slate-50 ">
            Tình trạng hôn nhân
          </label>
          <div className="w-full flex flex-col gap-2">
            <Select
              options={optionsRelationship}
              onChange={(data: any) => {
                setValue('marry', data.value);
              }}
              placeholder="Tình trạng hôn nhân"
              value={watch('marry')}
            />
          </div>
        </div>
      </div>
      <div className="form-group flex justify-end items-center">
        <ButtonSecondary className="mr-4" onClick={onClose}>
          Hủy bỏ
        </ButtonSecondary>
        <ButtonPrimary loading={loading}>Cập nhật</ButtonPrimary>
      </div>
    </form>
  );
};

export default EditProfileGeneral;
