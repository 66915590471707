import classNames from 'classnames';
import React, { useState } from 'react';
import { HiThumbUp, HiThumbDown } from 'react-icons/hi';
import { AiFillMessage } from 'react-icons/ai';
import { IoCopy } from 'react-icons/io5';
import ShareIcon from '../../../assets/icons/share.png';
import { IPostModel } from '../../../models';
import PostDonate from '../../post-donate/post-donate.component';
import _ from 'lodash';
import ModalSharePost from '../../../pages/home/components/modal.share.post';
import { useReactPost } from '../../../hooks/use-react-post/useReactPost';
import toast from 'react-hot-toast';
import ConfirmReactPost from '../../cofirm-react-post/cofirm-react-post.component';

interface PostActionsComponentProps {
  post: IPostModel;
  isPostUser: boolean;
}

type ReactPostState = {
  visible: boolean;
  react: 'like' | 'dislike' | 'idle';
};

const PostActionsComponent = ({ post, isPostUser }: PostActionsComponentProps) => {
  const [visibleShare, setVisibleShare] = useState(false);
  const { liked, disliked, handleLikePost, handleDisLikePost } = useReactPost({ postId: _.get(post, 'id', '') });
  const [reactPostState, setReactPostState] = useState<ReactPostState>({
    visible: false,
    react: 'idle',
  });

  const showModalSharePreview = () => {
    setVisibleShare(true);
  };

  const onLikePost = () => {
    if (post?.currentUserHasVoteUp || post?.currentUserHasVoteDown || liked || disliked) {
      toast.success('Bạn đã tương tác với bài viết này!', {
        position: 'top-right',
      });
      return;
    }
    setReactPostState({
      visible: true,
      react: 'like',
    });
  };

  const onDisLikePost = () => {
    if (post?.currentUserHasVoteUp || post?.currentUserHasVoteDown || liked || disliked) {
      toast.success('Bạn đã tương tác với bài viết này!', {
        position: 'top-right',
      });
      return;
    }

    setReactPostState({
      visible: true,
      react: 'dislike',
    });
  };

  const onConfirmReact = async () => {
    const { react } = reactPostState;
    switch (react) {
      case 'idle':
        break;
      case 'dislike':
        await handleDisLikePost();
        break;
      case 'like':
        await handleLikePost();
        break;

      default:
        break;
    }

    setReactPostState({
      visible: false,
      react: 'idle',
    });
  };

  return (
    <>
      {reactPostState.visible ? (
        <ConfirmReactPost
          isLike={reactPostState.react === 'like'}
          visible={reactPostState.visible}
          onClose={() =>
            setReactPostState({
              visible: false,
              react: 'idle',
            })
          }
          onCancel={() =>
            setReactPostState({
              visible: false,
              react: 'idle',
            })
          }
          onConfirm={onConfirmReact}
        />
      ) : null}

      <div className="sm:p-4 p-2.5 flex items-center gap-4 text-xs font-semibold">
        <div>
          <div className="flex items-center gap-2.5">
            <button
              type="button"
              className={classNames(`button-icon   dark:bg-slate-700`, {
                'text-blue-500 bg-blue-100': post?.currentUserHasVoteUp || liked,
                'text-slate-800 bg-slate-200 dark:text-slate-50': !post?.currentUserHasVoteUp && !liked,
              })}
              onClick={() => {
                onLikePost();
              }}
            >
              <HiThumbUp size={16} />
            </button>
            {_.get(post, 'voteUpCount', 0) + (liked ? 1 : 0)}
          </div>
        </div>
        <div>
          <div className="flex items-center gap-2.5">
            <button
              type="button"
              className={classNames(`button-icon   dark:bg-slate-700`, {
                'text-red-500 bg-red-100': post?.currentUserHasVoteDown || disliked,
                'text-slate-800 bg-slate-200 dark:text-slate-50': !post?.currentUserHasVoteDown && !disliked,
              })}
              onClick={() => {
                onDisLikePost();
              }}
            >
              <HiThumbDown size={16} />
            </button>
            {_.get(post, 'voteDownCount', 0) + (disliked ? 1 : 0)}
          </div>
        </div>
        <div>
          <div className="flex items-center gap-2.5">
            <button
              type="button"
              className="button-icon bg-slate-200/70 dark:bg-slate-700"
              onClick={() => {
                // setShowComment(!isShowComment);
                // setPageNumberComment(1);
              }}
            >
              <p className="flex items- gap-3 text-slate-800 dark:text-slate-50">
                <AiFillMessage size={16} />
              </p>
            </button>
            {post?.commentsCount}
          </div>
        </div>
        {!isPostUser && (
          <div className="ml-auto">
            <PostDonate post={post} />
          </div>
        )}
        <button type="button" className={isPostUser ? 'button-icon ml-auto' : 'button-icon'}>
          <IoCopy size={16} />
        </button>
        <button
          type="button"
          className={isPostUser ? 'button-icon ml-auto' : 'button-icon'}
          onClick={showModalSharePreview}
        >
          <img src={ShareIcon} alt="share" className="w-4" />
        </button>
      </div>
      {visibleShare && (
        <ModalSharePost
          onFetch={() => {
            //
          }}
          isOpen={visibleShare}
          reset={() => setVisibleShare(false)}
          post={post}
        />
      )}
    </>
  );
};

export default PostActionsComponent;
