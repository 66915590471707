import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCategory } from '../../hooks/category/userCategory';
import { RootState } from '../../configuration';
import { useSelector } from 'react-redux';
import InputComponent from '../../components/input/input.component';
import Select from '../../select/select.component';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from '../../components/loading/loading.component';
import { Post } from '../../components/post/post.component';
import _ from 'lodash';
import { useCategories } from '../../hooks/use-categories/useCategories';

export const CategoryPage = () => {
  const { categoryId } = useParams();
  const { getPostCategory } = useCategories({ type: 'post' });

  const { loading, getPostByCategory, postsByCategory, hasMore: hasMorePosts } = useCategory();
  const userProfile = useSelector((state: RootState) => state.features.auth.profile);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [orderType, setOrderType] = useState(null);
  const currentCategory = getPostCategory?.(categoryId ?? '');

  const orderTypeOption = [
    { label: 'Mới nhất', value: 1 },
    { label: 'Cũ nhất', value: 0 },
  ];

  const fetchMoreData = () => {
    if (!hasMorePosts) return;
    setPage(page + 1);
  };

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setPage(1);
  };

  useEffect(() => {
    if (categoryId) {
      getPostByCategory({
        userId: _.get(userProfile, 'userId', ''),
        categoryId,
        pageNumber: page,
        pageSize: 10,
        search,
        orderType,
      });
    }
  }, [categoryId, page, userProfile, search, orderType]);

  return (
    <div>
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold">Posts for {currentCategory?.name}</h1>

          <div className="grid grid-cols-2 gap-2 w-1/2">
            <InputComponent
              onChange={handleChangeSearch}
              placeholder="Tìm kiếm"
              className="border-[1px] !border-black border-solid"
            />
            <Select
              className="border-[1px] border-black border-solid"
              options={orderTypeOption}
              onChange={(data: any) => {
                setOrderType(data.value);
                setPage(1);
              }}
              placeholder="Chọn thời gian"
              value={orderType}
            />
          </div>
        </div>

        {postsByCategory && postsByCategory.data.length === 0 && !loading ? (
          <p className="text-center">Không tìm thấy bài viết nào cho hashtag này.</p>
        ) : (
          <InfiniteScroll
            dataLength={postsByCategory?.data.length || 0}
            next={fetchMoreData}
            hasMore={hasMorePosts}
            loader={<Loading classname="mx-auto" />}
          >
            <div className="space-y-6">
              {postsByCategory?.data.map((post) => (
                <Post getPostCategory={getPostCategory} key={post.id} post={post} />
              ))}
            </div>
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
};
