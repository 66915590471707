import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { usePostUncheckHook } from '../../hooks/post/usePostUncheck';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from '../../components/loading/loading.component';
import { Post } from '../../components';
import { RootState } from '../../configuration';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import _size from 'lodash/size';

const PostUncheck = (props) => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const profileUser = useSelector((state: RootState) => state.features.auth.profile);
  const { data: listPost, hasMore, loading } = usePostUncheckHook({ pageNumber });
  return (
    <div className="lg:flex 2xl:gap-16 gap-12 max-w-[1065px] mx-auto">
      <Helmet>
        <title>Cab VN</title>
        <meta name="home" content="Cab VN" />
      </Helmet>
      <div className="w-[680px] mx-auto">
        <main className="w-full">
          <div className="mb-8 text-xl font-bold text-center">Danh sách bài viết chờ duyệt của bạn</div>
          {loading ? <Loading classname="mx-auto" /> : null}

          {_size(listPost) ? (
            <InfiniteScroll
              dataLength={listPost.length}
              next={() => {
                setPageNumber((prev) => prev + 1);
              }}
              hasMore={hasMore}
              loader={<Loading classname="mx-auto" />}
              endMessage={null}
              scrollableTarget="body"
              className="flex-1 xl:space-y-6 space-y-3"
            >
              {listPost?.reverse()?.map((post, index) => {
                return <Post isUnCheck key={post.id} post={post} isPostUser={post.userId === profileUser?.userId} />;
              })}
            </InfiniteScroll>
          ) : null}
        </main>
      </div>

      <div className="flex-1" />
    </div>
  );
};

export default PostUncheck;
