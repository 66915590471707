/* eslint-disable no-await-in-loop */
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { fetchUsersApi } from '../../../api/admin/user.api';
import { fetchPostsApi, fetchReportPostByStateApi, fetchReportPostsApi } from '../../../api/admin/post.api';

export const useReportPostManagementHook = ({ isRefetch, status }: { isRefetch?: boolean; status?: any }) => {
  const [posts, setPosts] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    getUsers();
  }, [isRefetch, status]);
  const getUsers = async () => {
    try {
      setLoading(true);
      const { data } = await fetchReportPostByStateApi(status);
      setPosts(data);
    } catch {
      //
    } finally {
      setLoading(false);
    }
  };
  return {
    data: posts,
    loading,
  };
};
