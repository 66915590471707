import React, { useState } from 'react';
import { Modal, Button, Input } from 'antd';
import { MdOutlineReport } from 'react-icons/md';
import toast from 'react-hot-toast';
import { reportPostApi } from '../../api/posts/report-post.apt';
import { useSelector } from 'react-redux';
import { RootState } from '../../configuration';
import _ from 'lodash';

export const ReportPostDialog = ({ postId, onwerPostReportId }: any) => {
  const [open, setOpen] = useState(false);
  const [reportText, setReportText] = useState('');
  const [reportReason, setReportReason] = useState('');
  const profileUser = useSelector((state: RootState) => state.features.auth.profile);
  const profileUserID = _.get(profileUser, 'userId');
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleReportChange = (event) => {
    setReportText(event.target.value);
  };

  const handleSubmit = () => {
    // Handle the submit logic here
    console.log('Report submitted:', reportText);
    reportPostApi({
      postId,
      content: reportText,
      onwerPostReportId,
      reportPostValue: Number(reportReason),
    });
    setOpen(false);
    toast.success('Báo cáo thành công');
  };

  return (
    <div>
      <button
        onClick={handleClickOpen}
        className="rounded-md flex items-center space-x-2 w-full p-2 hover:bg-gray-1 dark:hover:bg-gray-10"
      >
        <MdOutlineReport size={14} />
        <span className="text-xs">Báo cáo bài biết</span>
      </button>
      <Modal title="Báo cáo" visible={open} onOk={handleSubmit} onCancel={handleClose} okText="Gửi " cancelText="Huỷ">
        <div className="flex flex-col gap-2">
          <p>Vì sao bạn chọn báo cáo bài đăng? Vui lòng cho biết nguyên nhân</p>
          <select onChange={(event) => setReportReason(event.target.value)} value={reportReason}>
            <option value="">Chọn lý do báo cáo</option>
            <option value={ReportReason.Spam}>Spam</option>
            <option value={ReportReason.Inappropriate}>Nội dung không phù hợp</option>
            <option value={ReportReason.HateSpeech}>Lạm dụng</option>
            <option value={ReportReason.Violence}>Bạo lực</option>
            <option value={ReportReason.Harassment}>Quấy rối</option>
            <option value={ReportReason.IntellectualPropertyViolation}>Vi phạm sở hữu trí tuệ</option>
            <option value={ReportReason.SuicideOrSelfHarm}>Tự tử hoặc tự làm hại</option>
            <option value={ReportReason.Misinformation}>Thông tin sai lệch</option>
            <option value={ReportReason.ChildEndangerment}>Nguy hiểm cho trẻ em</option>
            <option value={ReportReason.SexualContent}>Nội dung tình dục</option>
            <option value={ReportReason.Other}>Khác</option>
          </select>
          <p>Nội dung báo cáo :</p>
          <Input.TextArea rows={4} value={reportText} onChange={handleReportChange} placeholder="Nội dung" />
        </div>
      </Modal>
    </div>
  );
};
export enum ReportReason {
  Spam = 0,
  Inappropriate = 1,
  HateSpeech = 2,
  Violence = 3,
  Harassment = 4,
  IntellectualPropertyViolation = 5,
  SuicideOrSelfHarm = 6,
  Misinformation = 7,
  ChildEndangerment = 8,
  SexualContent = 9,
  Other = 10,
}
