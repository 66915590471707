import React, { useEffect, useState } from 'react';
import { usePostDetail } from '../../hooks/post/usePostDetail';
import PreviewPostDialog from '../preview-post-dialog/preview-post-dialog.componet';
import { IPostModel } from '../../models';
import { useNavigate } from 'react-router-dom';

export const PostDetailComponent = ({ postId }: { postId: string }) => {
  const { getDetailPost, postDetail } = usePostDetail({ shortId: postId });
  const navigate = useNavigate();

  useEffect(() => {
    getDetailPost(postId);
  }, [postId]);

  // useEffect(() => {
  //   if (!postDetail || !postDetail.id) navigate(`/`);
  // }, [postDetail]);

  const handleClosePreview = () => {
    navigate('/');
  };

  return postDetail?.id ? (
    <PreviewPostDialog
      visible={!!postDetail?.id}
      onclose={handleClosePreview}
      post={postDetail || ({} as IPostModel)}
      initialSlide={0}
    />
  ) : null;
};
