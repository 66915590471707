import { Send, Smile } from 'lucide-react';
import React, { useCallback, useEffect, useState } from 'react';
import data from '@emoji-mart/data';
import { sendCommentOfPost } from '../../../api';
import Picker from '@emoji-mart/react';

// import Picker from '@emoji-mart/react';

interface CommentInputComponentProps {
  postId: any;
  refreshCommentClient: () => void;
  userId: string;
  setPageNumberComment: (pageNumber: number) => void;
}

const CommentInputComponent = ({
  postId,
  refreshCommentClient,
  userId,
  setPageNumberComment,
}: CommentInputComponentProps) => {
  const [commentValue, setCommentValue] = useState<string>('');
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);

  const handleSendComment = async () => {
    if (!commentValue && !postId) {
      return;
    }
    const res = await sendCommentOfPost({ userId, postId, content: commentValue });
    if (res) {
      await refreshCommentClient();
      setCommentValue('');
      // setPageNumberComment(1);
    }
  };

  const onEmojiSelect = useCallback((emoji: any) => {
    setCommentValue((prev) => prev + emoji.native);
    setShowEmojiPicker(false);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest('.emoji-picker-container')) {
        setShowEmojiPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="p-4 border-t border-gray-200 dark:border-zinc-700">
      <div className="flex items-center gap-2">
        <div className="flex-1 relative">
          <input
            type="text"
            placeholder="Thêm bình luận..."
            className="w-full bg-gray-100 dark:bg-zinc-800 rounded-full px-4 py-2 text-sm outline-none pr-10"
            value={commentValue}
            onChange={(e) => setCommentValue(e.target.value)}
          />
          <button
            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
            className="absolute right-3 top-1/2 -translate-y-1/2"
          >
            <Smile size={20} className="text-slate-500" />
          </button>
          {showEmojiPicker && (
            <div className="absolute bottom-full right-0 mb-2 emoji-picker-container z-10">
              <Picker data={data} onEmojiSelect={onEmojiSelect} />
            </div>
          )}
        </div>
        <button
          onClick={() => handleSendComment()}
          className="text-blue-500 hover:text-blue-600 disabled:opacity-50"
          disabled={!commentValue}
        >
          <Send size={20} />
        </button>
      </div>
    </div>
  );
};

export default CommentInputComponent;
