/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable prefer-template */
/* eslint-disable react/self-closing-comp */
/* eslint-disable prettier/prettier */
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';
import { CommentsComponent } from '..';
// import { useUserProfile } from '../../hooks';
import utc from 'dayjs/plugin/utc';
import _get from 'lodash/get';
import { IPostCategory, IPostModel } from '../../models';
import { useSelector } from 'react-redux';
import { RootState } from '../../configuration';
import PostActionsComponent from './post-actions/post-actions.component';
import PostTypeImage from './post-types/post-type-image';
import PostTypeVideo from './post-types/post-type-video';
import PostTypeShare from './post-types/post-type-share';
import PostHastags from './post-hastags.component';
import PostMenu from './post-menu.component';
import PostHeader from './post-header.component';

dayjs.extend(utc);

export interface IPostProps {
  post?: IPostModel;
  isPostUser?: boolean;
  removePost?: any;
  isAdmin?: boolean;
  getPostCategory?: (id: string) => IPostCategory | undefined;
  isUnCheck?: boolean;
}

export const Post: React.FC<IPostProps> = (props) => {
  const { post, isPostUser, removePost, isAdmin, getPostCategory, isUnCheck = false } = props;
  // const { profile } = useUserProfile();
  const profileUser = useSelector((state: RootState) => state.features.auth.profile);
  const currentCategory = getPostCategory?.(post?.categoryId ?? '');
  const isPostShare = post?.postType === 'share';
  const isPostImage = post?.postType === 'image';
  const isPostVideo = post?.postType === 'video';

  const userId = useMemo(() => {
    return profileUser?.userId;
  }, [profileUser]);

  // hidden post
  const [isHiddenPost, setIsHiddenPost] = useState('-1');

  if (isHiddenPost === post?.id) {
    return null;
  }

  return (
    <div className="max-w-[680px] bg-white rounded-xl shadow-sm text-sm font-medium border1 dark:bg-dark2">
      <div className="flex gap-3 sm:p-4 p-2.5 text-sm font-medium">
        <PostHeader post={post} currentCategory={currentCategory ?? ({} as IPostCategory)} />

        {!isPostUser && !isAdmin ? (
          <PostMenu
            post={post as IPostModel}
            userId={userId || ''}
            removePost={removePost}
            setIsHiddenPost={setIsHiddenPost}
          />
        ) : null}
      </div>

      <div className="sm:px-4">
        <p className="text-sm text-zinc-800 dark:text-zinc-50">{post?.content}</p>
        <PostHastags post={post} />
        {isPostShare && <PostTypeShare post={post?.sharedPost} currentCategory={currentCategory} />}
      </div>
      {(isPostImage || post?.postType === 'normal') && <PostTypeImage post={post} />}
      {isPostVideo && <PostTypeVideo post={post} />}
      {!isAdmin && !isUnCheck && <PostActionsComponent post={post as IPostModel} isPostUser={isPostUser || false} />}
      {!isAdmin && !isUnCheck && <CommentsComponent post={post} userId={userId || ''} />}
      <div />
    </div>
  );
};
