import React, { useState } from 'react';
import { IComment } from '../../../models';
import { Link } from 'react-router-dom';
import Avatar from '../../avatar/avatar.component';
import classNames from 'classnames';
import { HiThumbDown, HiThumbUp } from 'react-icons/hi';
import dayjs from 'dayjs';
import { postService } from '../../../services/post.service';
import { useReplyComment } from '../../../hooks/post/useReplyComment';
import CommentReplyInputComponent from './comment-reply-input.component';
import { toast } from 'react-hot-toast';

interface SingleCommentComponentProps {
  comment: IComment;
  refreshCommentClient: () => void;
}

const SingleCommentComponent = ({ comment, refreshCommentClient }: SingleCommentComponentProps) => {
  const [replyCommentId, setReplyCommentId] = useState<string>('');
  const [isReplyComment, setIsReplyComment] = useState<boolean>(false);
  const [isShowReply, setIsShowReply] = useState<boolean>(false);
  const [pageNumberReplyComment, setPageNumberReplyComment] = useState<number>(1);

  const { id } = comment;

  const {
    data: replyCommentList,
    // hasMore: hasMoreReplyComment,
    getReplyCommentWhenReply,
  } = useReplyComment({
    pageNumber: pageNumberReplyComment,
    commentId: replyCommentId,
    visible: isShowReply,
  });

  const openReply = (id: string) => {
    if (!replyCommentId) {
      setIsReplyComment(true);
    }

    if (id === replyCommentId) {
      setIsReplyComment((prev) => !prev);
    } else {
      setIsReplyComment(true);
    }

    setReplyCommentId(id);
  };

  const isShowReplyList = isShowReply && id === replyCommentId;
  const isShowReplyInput = isReplyComment && id === replyCommentId;

  return (
    <div
      className={classNames(
        `flex items-start ${
          isShowReplyInput || isShowReplyList ? 'border-l-2 border-b-2 rounded-l-lg rounded-b-lg border-slate-50' : ''
        }`,
      )}
    >
      <div className="w-full z-10">
        <CommmentComponent
          {...comment}
          openReply={openReply}
          setIsShowReply={setIsShowReply}
          isShowReply={isShowReply}
          setReplyCommentId={setReplyCommentId}
          replyCommentId={replyCommentId}
          refreshCommentClient={refreshCommentClient}
        />
        {isShowReplyList ? (
          <div className="space-y-2 ml-16">
            {replyCommentList?.map((reply, index) => (
              <CommmentComponent key={index} {...reply} />
            ))}
          </div>
        ) : null}
        {isShowReplyInput && (
          <CommentReplyInputComponent
            commentId={id}
            getReplyCommentWhenReply={getReplyCommentWhenReply}
            setReplyCommentId={setReplyCommentId}
            isShowReply={isShowReply}
            setIsShowReply={setIsShowReply}
          />
        )}
      </div>
    </div>
  );
};

const CommmentComponent = (props: any) => {
  const {
    userId,
    avatar,
    userFullName,
    username,
    content,
    createdAt,
    currentUserHasLike,
    currentUserHasUnlike,
    totalReply,
    id,
    openReply,
    setReplyCommentId,
    setIsShowReply,
    isShowReply,
    replyCommentId,
    refreshCommentClient,
    downvotesCount,
    upvotesCount,
  } = props;

  const ToggleLike = async (id: string, type: number) => {
    try {
      if (currentUserHasLike || currentUserHasUnlike) {
        currentUserHasLike
          ? toast.error('Bạn đã thích bình luận này')
          : toast.error('Bạn đã không thích bình luận này');
        return;
      }
      await postService.likeComment({ commentId: id, type });
      await refreshCommentClient();
    } catch (error) {
      //
      toast.error('Có lỗi xảy ra');
    }
  };

  return (
    <div className="flex">
      <Link to={`/user/${username}`} className="cursor-pointer">
        <Avatar avatar={avatar} size="xs" label={userFullName} />
      </Link>
      <div className="ml-4 flex-1 mb-2">
        <div className="bg-gray-100 dark:bg-gray-800 rounded-lg p-2">
          {userFullName ? (
            <Link to={`/user/${username}`} className="text-black font-medium inline-block dark:text-white">
              {userFullName}
            </Link>
          ) : (
            <p className="text-black font-medium inline-block dark:text-white">Người dùng không xác định</p>
          )}
          <p className="mt-0.5">{content}</p>
        </div>
        <div className="flex items-center justify-star mt-1">
          <div className="flex justify-end items-center text-slate-800 dark:text-slate-50">
            <span className="text-xs ">{dayjs.utc(createdAt).local().fromNow()}</span>
            <p
              className={`${
                currentUserHasUnlike ? 'hidden' : 'block'
              } cursor-pointer text-xs ml-3 font-medium flex items-center gap-1 ${
                currentUserHasLike ? 'text-blue-500' : ''
              }`}
              onClick={() => ToggleLike(id, 1)}
            >
              <HiThumbUp size={16} /> {upvotesCount}
            </p>

            <p
              className={`${
                currentUserHasLike ? 'hidden' : 'block'
              } cursor-pointer text-xs ml-3 font-medium flex items-center gap-1 ${
                !currentUserHasUnlike ? 'text-slate-400' : 'text-red-500'
              }`}
              onClick={() => ToggleLike(id, -1)}
            >
              <HiThumbDown size={16} /> {downvotesCount}
            </p>
            {openReply && (
              <p className="cursor-pointer text-xs ml-3 font-medium" onClick={() => openReply(id)}>
                Phản hồi
              </p>
            )}
            {totalReply ? (
              <p
                className="cursor-pointer text-xs ml-3  text-slate-800 dark:text-slate-50 font-medium"
                onClick={() => {
                  setIsShowReply((prev) => !prev);
                  setReplyCommentId(id);
                }}
              >
                {isShowReply && id === replyCommentId ? 'Thu gọn' : `Xem tất cả ${totalReply} phản hồi`}
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleCommentComponent;
