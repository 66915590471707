import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { fetchUsersApi } from '../../../api/admin/user.api';
import { fetchPostsApi } from '../../../api/admin/post.api';

export const usePostManagementHook = ({
  pageNumber,
  isRefetch,
  status,
}: {
  pageNumber?: number;
  isRefetch?: boolean;
  status?: string;
}) => {
  const [posts, setPosts] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    getUsers(pageNumber);
  }, [pageNumber, isRefetch, status]);
  const getUsers = async (pageNumber) => {
    try {
      setLoading(true);
      const { data } = await fetchPostsApi(pageNumber, status);
      setPosts(data.data);
    } catch {
      //
    } finally {
      setLoading(false);
    }
  };
  return {
    data: posts,
    loading,
  };
};
