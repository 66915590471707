import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { IDatingProfileModel } from '../../../models/dating.model';
import Upload from './upload';
import { datingService } from '../../../services/dating.service';
import toast from 'react-hot-toast';
import _ from 'lodash';

function renderImage(albumUrls?: any[]) {
  if (_.isEmpty(albumUrls)) return <div className="text-slate-500">Bạn chưa có ảnh nào</div>;
  if (albumUrls?.length === 1) {
    return (
      <LazyLoadImage className="rounded-md object-cover w-full h-full" src={albumUrls[0].url} alt={albumUrls[0].url} />
    );
  }
  return (
    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 5, 1024: 3 }}>
      <Masonry gutter="12px">
        {albumUrls &&
          albumUrls?.map((image, index) => {
            return (
              <button key={index} className="relative block rounded-md">
                <LazyLoadImage
                  className={`${'test'} rounded-md object-cover w-full h-full`}
                  src={image}
                  alt={image}
                  //   onClick={() => {
                  //     if (exampleImages.length < 3) {
                  //     return;
                  //   }
                  //   setIndexViewImage(index);
                  //   setVisiblePreview(true);
                  // }}
                />
              </button>
            );
          })}
      </Masonry>
    </ResponsiveMasonry>
  );
}

const Albums = ({
  profile,
  getDatingProfile,
  disableUpload,
}: {
  profile?: IDatingProfileModel;
  getDatingProfile?: () => void;
  disableUpload?: boolean;
}) => {
  const handleUploadImage = async (urls: string[]) => {
    try {
      profile && (profile.albumUrls = [...profile.albumUrls, ...urls]);
      await datingService.updateDatingProfile(profile as IDatingProfileModel);
      toast.success('Đã cập nhật album ảnh');
      getDatingProfile && getDatingProfile();
    } catch (error) {
      toast.error('Không thể cập nhật ảnh đại diện');
      console.error('Error uploading avatar:', error);
    }
  };
  return (
    <div className="flex flex-col gap-4 mt-8 ">
      <section
        className={`${
          disableUpload ? '' : 'min-h-[600px]'
        } bg-white rounded-xl  shadow-sm text-sm font-medium border1 dark:bg-dark2 p-4`}
      >
        <div className=" text-black dark:text-white">
          <h3 className="font-bold text-lg"> Album ảnh </h3>
          {!disableUpload && <Upload handleUploadImage={handleUploadImage} />}
        </div>
        <div className="relative w-full h-full mt-4">{renderImage(profile?.albumUrls)}</div>
      </section>
    </div>
  );
};

export default Albums;
